import React, { useCallback } from "react";
import { Box } from "grommet";
import Attachments from "components/Attachments";
import SizeInput from "components/SizeInput";
import Colors from "components/Colors";
import OrientationInput from "components/OrientationInput";
import DropSide from "components/DropSide";
import EnvironmentInput from "components/EnvironmentInput";
import { useGlobalState, setActiveMenuItem } from "state";
import { MAIN_MANUAL_MENU_LABEL } from "components/Sidebar/consts";
import { ManualButton } from "./Sidebar.style";
import ModalTrigger from "components/ModalTrigger";

const defaultPosition = { top: 20, right: 20 };

export default function() {
  const [state, dispatch] = useGlobalState();

  const showManualInstructions = useCallback(
    () => dispatch(setActiveMenuItem(MAIN_MANUAL_MENU_LABEL)),
    [dispatch]
  );

  return (
    <React.Fragment>
      <DropSide label="Orientation">
        <ModalTrigger position={defaultPosition} modalName="Orientation" />
        <OrientationInput />
      </DropSide>
      <DropSide label="Size" width={400}>
        <ModalTrigger position={defaultPosition} modalName="Size" />
        <SizeInput />
      </DropSide>
      <DropSide label="Attachments">
        <ModalTrigger position={defaultPosition} modalName="Attachments" />
        <Attachments />
      </DropSide>
      <DropSide label="Color">
        <ModalTrigger position={defaultPosition} modalName="Color" />
        <Colors />
      </DropSide>
      <DropSide label="Environment">
        <ModalTrigger position={defaultPosition} modalName="Environment" />
        <EnvironmentInput />
      </DropSide>
      {/* <Box pad="large">
        <ManualButton
          label={MAIN_MANUAL_MENU_LABEL}
          onClick={showManualInstructions}
        />
      </Box> */}
    </React.Fragment>
  );
}
