import React, { useCallback } from "react";
import { StyledTabs, StyledTab, TabContentBox } from "./DetailsPanel.style";
import Visuals from "./Visuals";
import Tools from "./Tools";
import Guides from "./Guides";
import DropSideModal from "components/DropSideModal";
import { useGlobalState, hideSidebarModal } from "state";
import { selectActiveSidebarModal, selectPreset } from "state/selectors";

export default function() {
  const [state, dispatch] = useGlobalState();

  const activeModalName = selectActiveSidebarModal(state);
  const preset = selectPreset(state);

  const handleClose = useCallback(() => dispatch(hideSidebarModal()), [
    dispatch
  ]);

  if (!activeModalName) {
    return null;
  }

  const guideContent =
    Guides[activeModalName] && Guides[activeModalName](preset);

  const visualContent =
    Visuals[activeModalName] && Visuals[activeModalName](preset);

  const toolsContent = Tools[activeModalName] && Tools[activeModalName](preset);

  const tabs = [];
  if (!!visualContent)
    tabs.push(
      <StyledTab key="visual-tab" title="Visual">
        <TabContentBox>{visualContent}</TabContentBox>
      </StyledTab>
    );
  if (!!guideContent)
    tabs.push(
      <StyledTab key="guide-tab" title="Guide">
        <TabContentBox>{guideContent}</TabContentBox>
      </StyledTab>
    );

  if (!!toolsContent)
    tabs.push(
      <StyledTab key="tools-tab" title={`Parts & Tools`}>
        <TabContentBox>{guideContent}</TabContentBox>
      </StyledTab>
    );

  return (
    <DropSideModal onClose={handleClose}>
      <StyledTabs justify="start">{tabs}</StyledTabs>
    </DropSideModal>
  );
}
