import consts from "./consts";
import { initialState } from "./reducer";

export const buildInitialStateFromSearch = (search = "") => {
  if (search === "") {
    return initialState.model;
  }
  const urlParams = new URLSearchParams(search);

  let modelState = {
    ...initialState.model
  };

  const buildStateParam = createdStateParamBuilder(urlParams);

  modelState = buildStateParam(
    modelState,
    URL_PARAMS_NAMES.moduleType,
    "moduleType",
    consts.moduleTypes
  );

  modelState = buildStateParam(
    modelState,
    URL_PARAMS_NAMES.attachments,
    "attachments",
    Object.values(consts.attachments)
  );

  modelState = buildStateParam(
    modelState,
    URL_PARAMS_NAMES.wide,
    "wide",
    consts.size.wide
  );

  modelState = buildStateParam(
    modelState,
    URL_PARAMS_NAMES.long,
    "long",
    consts.size.long
  );

  modelState = buildStateParam(
    modelState,
    URL_PARAMS_NAMES.height,
    "height",
    consts.size.height
  );

  modelState = buildStateParam(
    modelState,
    URL_PARAMS_NAMES.orientation,
    "orientation",
    Object.values(consts.orientation)
  );

  modelState = buildStateParam(
    modelState,
    URL_PARAMS_NAMES.legsColor,
    "legsColor",
    consts.colors.map(c => c.value)
  );

  modelState = buildStateParam(
    modelState,
    URL_PARAMS_NAMES.trussColor,
    "trussColor",
    consts.colors.map(c => c.value)
  );

  modelState = buildStateParam(
    modelState,
    URL_PARAMS_NAMES.setting,
    "setting",
    Object.values(consts.environment.setting)
  );

  modelState = buildStateParam(
    modelState,
    URL_PARAMS_NAMES.utilization,
    "utilization",
    Object.values(consts.environment.utilization)
  );

  modelState = buildStateParam(
    modelState,
    URL_PARAMS_NAMES.snowBrace,
    "snowBrace",
    ["true", "false"]
  );

  modelState = buildStateParam(
    modelState,
    URL_PARAMS_NAMES.preset,
    "preset",
    Object.values(consts.presets)
  );

  if (modelState.preset === consts.presets.rooftop) {
    modelState.attachments = consts.attachments.tTrack;
    modelState.setting = consts.environment.setting.city;
    modelState.wide = consts.size.wide[1];
  }

  if (modelState.preset === consts.presets.ground) {
    modelState.setting = consts.environment.setting.desert;
    modelState.wide = consts.size.wide[2];
  }

  const disabledSizes60 = urlParams.get(
    URL_PARAMS_NAMES.disabledSizes[consts.moduleTypes[0]]
  );

  const disabledSizes72 = urlParams.get(
    URL_PARAMS_NAMES.disabledSizes[consts.moduleTypes[1]]
  );

  modelState.disabledSizes = {
    [consts.moduleTypes[0]]: !!disabledSizes60
      ? parseSizesString(disabledSizes60)
      : [],
    [consts.moduleTypes[1]]: !!disabledSizes72
      ? parseSizesString(disabledSizes72)
      : []
  };

  return modelState;
};

export const createParamValidor = (availibleValues = []) => value =>
  availibleValues.includes(value);

export const createdStateParamBuilder = (
  urlParams = new URLSearchParams("")
) => (state, paramName = "", statePropName = "", avalibleValues = []) => {
  const newState = { ...state };
  const value = urlParams.get(paramName);
  const iValid = createParamValidor(avalibleValues);

  if (value && iValid(value)) {
    newState[statePropName] = value;
  }
  return newState;
};

export const URL_PARAMS_NAMES = {
  moduleType: "module_type",
  attachments: "attach",
  wide: "panels_wide",
  long: "panels_long",
  height: "height",
  orientation: "orientation",
  legsColor: "truss_and_leg_color",
  trussColor: "rail_color",
  setting: "setting",
  utilization: "utilization",
  snowBrace: "snow_brace",
  disabledSizes: {
    [consts.moduleTypes[0]]: "disabled_60",
    [consts.moduleTypes[1]]: "disabled_72"
  },
  preset: "preset"
};

const getQueryColorByHexValue = value => {
  const color = consts.colors.filter(c => c.value === value)[0];
  if (color) {
    return color.selectColor;
  }
  return value;
};

export const formatStateToParams = (modelState = initialState.model) => {
  const disabledSizes60 = modelState.disabledSizes[consts.moduleTypes[0]] || [];
  const disabledSizes72 = modelState.disabledSizes[consts.moduleTypes[1]] || [];

  return {
    [URL_PARAMS_NAMES.moduleType]: modelState.moduleType,
    [URL_PARAMS_NAMES.orientation]: modelState.orientation,
    [URL_PARAMS_NAMES.snowBrace]: modelState.snowBrace,
    [URL_PARAMS_NAMES.attachments]: modelState.attachments,
    [URL_PARAMS_NAMES.wide]: modelState.wide,
    [URL_PARAMS_NAMES.long]: modelState.long,
    [URL_PARAMS_NAMES.height]: modelState.height,
    [URL_PARAMS_NAMES.legsColor]: getQueryColorByHexValue(modelState.legsColor),
    [URL_PARAMS_NAMES.trussColor]: getQueryColorByHexValue(
      modelState.trussColor
    ),
    [URL_PARAMS_NAMES.setting]: modelState.setting,
    [URL_PARAMS_NAMES.utilization]: modelState.utilization,
    [URL_PARAMS_NAMES.disabledSizes[
      consts.moduleTypes[0]
    ]]: disabledSizes60.join(","),
    [URL_PARAMS_NAMES.disabledSizes[
      consts.moduleTypes[1]
    ]]: disabledSizes72.join(","),
    [URL_PARAMS_NAMES.preset]: modelState.preset
  };
};

export const buildUrlParamsString = (params = {}) => {
  const urlParams = new URLSearchParams();
  for (const entry of Object.entries(params)) {
    const [key, value] = entry;
    if (value) {
      urlParams.set(key, value.toString());
    }
  }
  return urlParams.toString();
};

export const parseSizesString = (sizeStr = "") => sizeStr.split(",");
