import React, { useEffect } from "react";
import Sidebar from "components/Sidebar";
import Layout from "components/Layout";
import Model from "components/Model";
import Footer from "components/Footer";
import {
  useGlobalReducer,
  StateContext,
  useHistoryPush,
  useVendorUpdate
} from "state";
import { Grommet } from "grommet";
import theme from "components/theme";

function App() {
  const [state, dispatch] = useGlobalReducer(window.location.search);

  useHistoryPush(state);
  useVendorUpdate(state);

  return (
    <Grommet theme={theme} style={{ width: "100%", height: "100%" }}>
      <StateContext.Provider value={{ state, dispatch }}>
        <Layout
          sider={<Sidebar></Sidebar>}
          body={<Model></Model>}
          footer={<Footer />}
        />
      </StateContext.Provider>
    </Grommet>
  );
}

export default App;
