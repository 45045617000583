import { consts } from "state";
import { hideNodes, showNodes } from "./helpers";
import env_uid from "../../../environment.config";

export default async (sketchfab, { setting }) => {
  hideNodes(sketchfab, ["env_roof", "env_desert"]);

  switch (setting) {
    case consts.environment.setting.empty:
      sketchfab.setEnvironment({
        uid: env_uid.empty_uid,
        lightIntesity: 1.2,
        rotation: (Math.PI / 180) * 245
      });
      hideNodes(sketchfab, ["env_desert", "env_roof"]);
      break;
    case consts.environment.setting.desert:
      sketchfab.setEnvironment({
        uid: env_uid.desert_uid,
        lightIntesity: 2,
        rotation: (Math.PI / 180) * 235
      });
      showNodes(sketchfab, ["env_desert"]);
      break;
    case consts.environment.setting.city:
      sketchfab.setEnvironment({
        uid: env_uid.roof_uid,
        lightIntesity: 1.2,
        rotation: (Math.PI / 180) * 245
      });
      showNodes(sketchfab, ["env_roof"]);
      break;

    default:
      break;
  }
};
