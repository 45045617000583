import { MANUAL_MENU_LABELS } from "components/Sidebar/consts";

export const MANUAL_ANNOTATIONS_NAMES = {
  // TODO
  [MANUAL_MENU_LABELS[0]]: ["Truss"],
  [MANUAL_MENU_LABELS[1]]: ["Truss"],
  [MANUAL_MENU_LABELS[2]]: ["Truss"],
  [MANUAL_MENU_LABELS[3]]: ["Truss"],
  [MANUAL_MENU_LABELS[4]]: ["Truss"],
  [MANUAL_MENU_LABELS[5]]: ["Truss"],
  [MANUAL_MENU_LABELS[6]]: ["Truss"],
  [MANUAL_MENU_LABELS[7]]: ["Truss"]
};

export const MANUAL_ANNOTATIONS_POINTS = {
  // TODO
};
