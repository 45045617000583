import React from "react";
import Icon from "./Icon";
import { HomeImage } from "./Icon.style";
import HomeImageHovered from "./home_white.png";
import HomeImageUnhovered from "./home_grey.png";

export default function(props) {
  return (
    <Icon
      {...props}
      content={
        <HomeImage
          src={props.isHovered ? HomeImageHovered : HomeImageUnhovered}
        />
      }
    />
  );
}
