import React from "react";
import { cameraOptions, defaultOptions } from "./options";
import { useSketchfab, useDimensions, useModelHandler } from "./Model.hooks";
import ModelView from "./ModelView";

const uid = "1b408807a80d450395be92122fcbc84b";

const options = { ...defaultOptions, ...cameraOptions };

export default ({ init, setInit }) => {
  const frameId = "main-model";

  const [isLoading, sketchfab] = useSketchfab(
    uid,
    frameId,
    options,
    init,
    setInit
  );

  useModelHandler(sketchfab);
  useDimensions(sketchfab);

  return (
    <ModelView isLoading={isLoading} frameId={frameId} sketchfab={sketchfab} />
  );
};
