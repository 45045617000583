import React from "react";
import Container, { ModelPanel } from "./Model.style";
import AnnotationsPanel from "../AnnotationsPanel";
import DimensionTrigger from "components/DimensionTrigger";

export default function({ frameId, sketchfab, isLoading }) {
  return (
    <Container>
      {isLoading && <span className="loading">Loading</span>}
      <iframe
        title="Sketchfab model"
        id={frameId}
        className={isLoading ? "hidden" : "visible"}
      />
      {!isLoading && (
        <ModelPanel>
          <DimensionTrigger />
          <AnnotationsPanel sketchfab={sketchfab} />
        </ModelPanel>
      )}
    </Container>
  );
}
