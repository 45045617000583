import styled from "styled-components";
import { Box, Button as ButtonBase } from "grommet";

export const Container = styled(Box)`
  height: auto;
  width: 100%;
  div {
    overflow-y: auto;
  }

  div,
  div > div {
    height: auto;
    width: 100%;
  }

  div > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div > div > svg {
    height: auto;
    width: 100%;
  }
`;

Container.defaultProps = {
  fill: "vertical"
};

export const Button = styled(ButtonBase)`
  background-color: black;
  white-space: nowrap;
`;
