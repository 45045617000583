import React from "react";
import { Box, Text } from "grommet";
import { Label, BackButton, Wrapper } from "./DropSide.style";
import Arrow from "components/Arrow";
import { useDropSide } from "./DropSide.hooks";
import SwipeLayer from "components/SwipeLayer";

export default ({ label, children }) => {
  const { isControlVisible, showControls, hideControls } = useDropSide(label);

  return (
    <React.Fragment>
      <Wrapper onClick={showControls} className={(label || "").toLowerCase()}>
        <Text>{label}</Text>
        <Arrow direction="right" />
      </Wrapper>
      {isControlVisible && (
        <SwipeLayer onClose={hideControls}>
          <BackButton onClick={hideControls} />
          <Box style={{ position: "relative" }} pad="medium">
            <Label>{label}</Label>
            {children}
          </Box>
        </SwipeLayer>
      )}
    </React.Fragment>
  );
};
