import consts from "state/consts";

export const groundOptions = [
  {
    label: "Concrete Pads",
    value: consts.attachments.pads
  },
  {
    label: "Concrete Beams",
    value: consts.attachments.beams
  },
  {
    label: "Earth Anchors",
    value: consts.attachments.anchors
  }
];

export const rooftopOptions = [
  {
    label: "T-track",
    value: consts.attachments.tTrack
  }
];
