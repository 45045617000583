import { consts } from "state";
import { transformNode } from "./helpers";
import { TRANSLATE_Z_01, TRANSLATE_Z_02 } from "./consts/long";
import { SCALE_X as MODULE_TYPE_SCALE_X } from "./consts/moduleTypes";

export default async (sketchfab, { moduleType, long, orientation }) => {
  transformNode(sketchfab, NODE_NAMES.supoprt1, {
    translate: {
      z: TRANSLATE_Z_02[long]
    },
    scale: {
      x: MODULE_TYPE_SCALE_X[moduleType],
      y: getScaleY(orientation, long, SUPPORT_01_SCALE_Y)
    }
  });

  transformNode(sketchfab, NODE_NAMES.support2, {
    translate: {
      z: TRANSLATE_Z_01[long]
    },
    scale: {
      x: MODULE_TYPE_SCALE_X[moduleType],
      y: getScaleY(orientation, long, SUPPORT_02_SCALE_Y)
    }
  });
};

const NODE_NAMES = {
  supoprt1: "support_01",
  support2: "support_02",
  ground: "ground_02"
};

const SUPPORT_01_SCALE_Y = {
  [consts.size.long[0]]: 0.983,
  [consts.size.long[1]]: 0.989,
  [consts.size.long[2]]: 1.0
};

const SUPPORT_02_SCALE_Y = {
  [consts.size.long[0]]: 0.914,
  [consts.size.long[1]]: 0.894,
  [consts.size.long[2]]: 0.875
};

const getScaleY = (orientation, long, yScales) => {
  if (orientation === consts.orientation.front) {
    return yScales[long];
  }
  return 1;
};
