import { Box, Button } from "grommet";
import styled from "styled-components";

const FooterBox = styled(Box)``;

FooterBox.defaultProps = {
  flex: "grow",
  align: "center",
  border: "right",
  justify: "center",
  pad: "medium"
};

const MoreInfoLink = styled.a`
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 8px;
  white-space: nowrap;
  cursor: pointer;
`;

MoreInfoLink.defaultProps = {
  children: "More info"
};

export { FooterBox, MoreInfoLink };
