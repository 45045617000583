import React, { useEffect, useCallback } from "react";
import InfoIcon from "components/InfoIcon";
import {
  hideSidebarModal,
  changeActiveSidebarModal,
  useGlobalState
} from "state";
import { InfoIconBox } from "./ModalTrigger.style";

export default function({ position, modalName }) {
  const [state, dispatch] = useGlobalState();

  useEffect(() => {
    return () => dispatch(hideSidebarModal());
  }, [dispatch]);

  const setModal = useCallback(
    () => dispatch(changeActiveSidebarModal(modalName)),
    [dispatch, modalName]
  );

  return position ? (
    <InfoIconBox {...position}>
      <InfoIcon onClick={setModal} />
    </InfoIconBox>
  ) : (
    <InfoIcon onClick={setModal} />
  );
}
