import { useGlobalState } from "state";
import { selectMeasurements, selectOrientation } from "state/selectors";
import measurementsImages from "./measurements";

export const useDimensionSizes = () => {
  const [state] = useGlobalState();
  const measurements = selectMeasurements(state);
  const orientation = selectOrientation(state);

  const getSvgPath = orientation => measurementsImages[orientation];

  const rewriteSizes = sizeNodes => {
    for (let i = 0; i < sizeNodes.length; i++) {
      const measurementName = sizeNodes[i].getAttribute("data-id");
      sizeNodes[i].textContent = measurements[measurementName];
    }
  };

  return { rewriteSizes, svgImage: getSvgPath(orientation) };
};
