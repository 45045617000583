import styled from "styled-components";
import theme from "components/theme";

export const LayoutWrapper = styled.div`
  background-image: url(${require("./background-pattern.png")});
  background-size: 80px 47px;
  background-color: ${theme.global.colors.background};
  overflow: hidden;
  width: 100%;
  height: 100%;
`;
