import React from "react";
import { Box, ResponsiveContext } from "grommet";
import { LayoutWrapper } from "./Layout.style";
import theme from "components/theme";
import Logo from "components/Logo";

export default ({ sider, body, footer }) => {
  const size = React.useContext(ResponsiveContext);
  const isSmall = size === "small";
  const direction = isSmall ? "column" : "row";
  const sideWidth = isSmall ? "100%" : theme.sider.width;

  const order = isSmall ? 1 : 0;

  return (
    <LayoutWrapper>
      <Box fill="vertical" direction={direction}>
        <Box
          width={sideWidth}
          background={theme.sider.background}
          style={{ order, zIndex: 20 }}
        >
          {!isSmall && <Logo />}
          {isSmall && <Box border="bottom" />}
          {sider}
        </Box>
        <Box flex="grow">
          {isSmall && <Logo />}
          <Box pad="large" fill="vertical" style={{ flex: 1 }}>
            {body}
          </Box>
        </Box>
      </Box>
    </LayoutWrapper>
  );
};
