import { consts } from "state";
import { hideNodes, showNodes } from "./helpers";
import flatten from "lodash/flatten";

export default async (sketchfab, { utilization }) => {
  hideNodes(sketchfab, flatten(Object.values(NODE_NAMES)));
  if (utilization) {
    showNodes(sketchfab, NODE_NAMES[utilization]);
  }
};

const NODE_NAMES = {
  [consts.environment.utilization.none]: [],
  [consts.environment.utilization.car]: ["car_g"],
  [consts.environment.utilization.hammock]: ["hammock"],
  [consts.environment.utilization.dining]: ["dining_table_g"],
  [consts.environment.utilization.picnic]: [
    /* "picnic_table_g" */
  ]
};
