import React from "react";
import { Text, Box } from "grommet";
import { consts } from "state";

const GuideHeading = ({ children }) => (
  <Box border="bottom" margin={{ bottom: "medium" }}>
    <Text size="24px" weight="bold">
      {children}
    </Text>
  </Box>
);
const GuideParagraph = ({ children }) => (
  <Box margin={{ bottom: "medium" }}>
    <Text>{children}</Text>
  </Box>
);

const Wrapper = ({ children }) => (
  <Box margin={{ vertical: "medium" }}>{children}</Box>
);

export default {
  Orientation: () => (
    <div>
      <GuideHeading>I.Intro</GuideHeading>
      <GuideParagraph>
        The canopy’s orientation is the direction of its pitch. Orientation is a
        factor in solar production and in precipitation management. Solar
        modules on a pitched canopy facing the horizon towards the sun (south in
        the northern hemisphere) will generate slightly more electricity than
        those on a flat canopy. <strong>Note:</strong> Precipitation will more
        rapidly drain from the surface of pitched front and lateral oriented
        canopies than from flat oriented canopies.
      </GuideParagraph>
      <GuideHeading>II. Flat Oreientation</GuideHeading>
      <GuideParagraph>
        <Text weight="bold">0 degree pitch.</Text> A flat canopy has two trusses
        that are parallel to the ground and are elevated at the same height.
      </GuideParagraph>
      <GuideHeading>III. Front Orientation</GuideHeading>
      <GuideParagraph>
        <strong>5 degree pitch to the front or back of the canopy.</strong> A
        front-oriented canopy has two trusses that are parallel to the ground,
        with the front truss elevated slightly lower than the back truss.
      </GuideParagraph>
      <GuideHeading>III. Lateral Orientation</GuideHeading>
      <GuideParagraph>
        <strong>5 degree pitch to either side of the canopy.</strong> A
        laterally-oriented canopy has two trusses that are slightly pitched
        relative to the ground and elevated at the same height. One side of the
        truss will be higher than the other.
      </GuideParagraph>
      <GuideHeading>IV. Orientation and Climate</GuideHeading>
      <GuideParagraph>
        In some areas certain canopy orientations are not structurally feasible
        due to local wind, snow and/or seismic conditions. These options will be
        locked on the orientation selection panel. Orientation selection may
        also impact available options presented in the <i>Size</i> selection
        category below, as different orientations allow for different width and
        length parameters under different loading and seismic conditions.{" "}
        <strong>Note</strong> that front-oriented canopies will have the lowest
        tolerance for both snow and wind loads and may not be permitted in your
        area.
      </GuideParagraph>
    </div>
  ),
  Size: preset => {
    switch (preset) {
      case consts.presets.ground:
        return (
          <div>
            <GuideHeading>I.Intro</GuideHeading>
            <GuideParagraph>
              Canopies can be built in a variety of sizes. Dimensions determine
              both the amount of usable space underneath the canopy, and the
              number of solar modules that it can support.
            </GuideParagraph>
            <GuideHeading>
              II. Module Quantity and Canopy Dimensions
            </GuideHeading>
            <GuideParagraph>
              Using the module selection table, control the width and length of
              the canopy by adding or reducing module rows and columns. Refer to
              the dimension panel on the configurator output dashboard below for
              your canopy’s exact dimensions, based on the number of rows and
              columns that you have selected.
            </GuideParagraph>
            <GuideHeading>III. Module Type and Canopy Dimensions</GuideHeading>
            <GuideParagraph>
              The canopy can incorporate a range of solar module brands and
              models of both standard 60 cell and slightly larger 72 cell
              capacities. Note that selecting different module cell capacities
              can impact the dimensions of your canopy.
            </GuideParagraph>
            <GuideHeading>IV. Module Cantilever</GuideHeading>
            <GuideParagraph>
              Solar Modules may cantilever up to 6’ off of the truss on the
              front and back of the canopy, and up to 6” off of the side rails.
            </GuideParagraph>
            <GuideHeading>
              V. Standard Carport Dimension Guidelines
            </GuideHeading>
            <GuideParagraph>
              Standard ground width for a single carport is 12’, or 2 rows of
              modules. Standard dimensions for a double carport are 22’ ground
              width, or 4 rows of modules. When selecting canopy{" "}
              <i>clearance</i> (see below), vehicle height must be factored to
              ensure adequate space.
            </GuideParagraph>
            <GuideHeading>
              VI. Module Quantity and Solar Production
            </GuideHeading>
            <GuideParagraph>
              The number of solar module rows and columns selected will
              determine the amount of electricity (kWh) that your canopy
              produces. An annual kWh production estimate, based on number of
              modules and your local solar conditions, can be found below on the
              configurator output dashboard.
              <strong>Note:</strong> kWh projections provided are estimates only
              based on generic solar performance data and do not take into
              account module brand and site specific factors. Consult your solar
              installation professional for more precise projections that
              account for module and site factors.
            </GuideParagraph>
            <GuideHeading>VII. Size and Climate</GuideHeading>
            <GuideParagraph>
              In some areas certain canopy sizes and module configurations are
              not structurally feasible due to local wind, snow and seismic
              conditions. These options will be locked on the module selection
              table.
            </GuideParagraph>
            <GuideHeading>VIII. Height and Clearance</GuideHeading>
            <GuideParagraph>
              Your canopy can be built to different heights to meet different
              needs.
              <i>Height</i> refers of the highest point of the canopy’s
              elevation, measured at the top of the solar module.{" "}
              <i>Clearance</i> refers to the distance from the ground to the
              lowest point of the canopy’s trusses. Clearance is an important
              factor when considering vertical space requirements for vehicle
              parking and other specific use cases. Note that for canopies with{" "}
              <i>Lateral</i> and <i>Front</i> orientations, clearance represents
              the lowest measured point from the ground to the bottom of the
              truss.
            </GuideParagraph>
          </div>
        );
      case consts.presets.rooftop:
        return (
          <div>
            <GuideHeading>I.Intro</GuideHeading>
            <GuideParagraph>
              Canopies can be built in a variety of sizes. Dimensions determine
              both the amount of usable space underneath the canopy, and the
              number of solar modules that it can support.
            </GuideParagraph>
            <GuideHeading>
              II. Module Quantity and Canopy Dimensions
            </GuideHeading>
            <GuideParagraph>
              Using the module selection table, control the width and length of
              the canopy by adding or reducing module rows and columns. Refer to
              the dimension panel on the configurator output dashboard below for
              your canopy’s exact dimensions, based on the number of rows and
              columns that you have selected.
            </GuideParagraph>
            <GuideHeading>III. Module Type and Canopy Dimensions</GuideHeading>
            <GuideParagraph>
              The canopy can incorporate a range of solar module brands and
              models of both standard 60 cell and slightly larger 72 cell
              capacities. Note that selecting different module cell capacities
              can impact the dimensions of your canopy.
            </GuideParagraph>
            <GuideHeading>IV. Module Cantilever</GuideHeading>
            <GuideParagraph>
              Solar Modules may cantilever up to 6’ off of the truss on the
              front and back of the canopy, and up to 6” off of the side rails.
            </GuideParagraph>
            <GuideHeading>
              V. Standard Carport Dimension Guidelines
            </GuideHeading>
            <GuideParagraph>
              Standard ground width for a single carport is 12’, or 2 rows of
              modules. Standard dimensions for a double carport are 22’ ground
              width, or 4 rows of modules. When selecting canopy{" "}
              <i>clearance</i> (see below), vehicle height must be factored to
              ensure adequate space.
            </GuideParagraph>
            <GuideHeading>
              VI. Module Quantity and Solar Production
            </GuideHeading>
            <GuideParagraph>
              The number of solar module rows and columns selected will
              determine the amount of electricity (kWh) that your canopy
              produces. An annual kWh production estimate, based on number of
              modules and your local solar conditions, can be found below on the
              configurator output dashboard. <strong>Note:</strong> kWh
              projections provided are estimates only based on generic solar
              performance data and do not take into account module brand and
              site specific factors. Consult your solar installation
              professional for more precise projections that account for module
              and site factors.
            </GuideParagraph>
            <GuideHeading>VII. Size and Climate</GuideHeading>
            <GuideParagraph>
              In some areas certain canopy sizes and module configurations are
              not structurally feasible due to local wind, snow and seismic
              conditions. These options will be locked on the module selection
              table.
            </GuideParagraph>
            <GuideHeading>VIII. Height and Clearance</GuideHeading>
            <GuideParagraph>
              Your canopy can be built to different heights to meet different
              needs. <i>Height</i> refers of the highest point of the canopy’s
              elevation, measured at the top of the solar module.{" "}
              <i>Clearance</i> refers to the distance from the ground to the
              lowest point of the canopy’s trusses. Clearance is an important
              factor when considering vertical space requirements for vehicle
              parking and other specific use cases. Note that for canopies with
              <i>Lateral</i> and <i>Front</i> orientations, clearance represents
              the lowest measured point from the ground to the bottom of the
              truss.
            </GuideParagraph>
          </div>
        );

      default:
        return null;
    }
  },
  Attachments: preset => {
    switch (preset) {
      case consts.presets.ground:
        return (
          <Wrapper>
            <GuideHeading>I.Intro</GuideHeading>
            <GuideParagraph>
              The Brooklyn Solar Canopy is engineered to attach to both concrete
              foundations, and earth anchors and ground screws. Optimal
              attachment options are influenced by local soil and frost
              conditions. Consult a BSCC representative for further guidelines.
            </GuideParagraph>
            <GuideHeading>II. Concrete Beams (Not included)</GuideHeading>
            <GuideParagraph>
              Parallel reinforced concrete beams spanning canopy ground length.
              Each canopy foot plate (6”x4”) is anchored to the beam with 4
              concrete anchors (included).Beam dimensions and size are
              determined by maximum downward and uplift load factors. Detailed
              beam size and construction guidelines available upon request of
              project contractor.
            </GuideParagraph>
            <GuideParagraph>
              <Text weight="bold">Option 1.</Text> Reinforced ready mix beams
              poured and cured on site.
            </GuideParagraph>
            <GuideParagraph>
              <Text weight="bold">Option 2.</Text> Precast reinforced beams
              placed in excavated ground, or positioned above ground at grade.
            </GuideParagraph>
            <GuideHeading>
              III. Isolated Concrete Pads (Not included)
            </GuideHeading>
            <GuideParagraph>
              Isolated reinforced square concrete pads poured and cured on site.
              Each canopy foot plate (6”x4”) is anchored to a single isolated
              pad with 4 concrete anchors (included). Pad dimensions and size
              are determined by maximum downward and uplift load factors.
              Further beam size and construction guidelines available upon
              request of project contractor.
            </GuideParagraph>
            <GuideHeading>IV. Earth Anchors (Included)</GuideHeading>
            <GuideParagraph>
              Heat treated aluminum alloy 46” threaded anchors. Each canopy foot
              plate is anchored to ground with a single earth anchor. Anchors
              can be installed using a standard impact wrench or driver. Earth
              anchors suitable for hardpan and Class 1 and 2 soils only.
              Included with order.
            </GuideParagraph>
          </Wrapper>
        );
      case consts.presets.rooftop:
        return (
          <Wrapper>
            <GuideHeading>I.Intro</GuideHeading>
            <GuideParagraph>
              For rooftop applications the Brooklyn Solar Canopy is engineered
              to attach to wood rafters and to solid masonry walls (i.e. brick,
              concrete, limestone, granite, etc.).
            </GuideParagraph>
            <GuideHeading>II. Wood rafters</GuideHeading>
            <GuideParagraph>
              The canopy feet integrate with the rigid vertical flange of an
              extruded aluminum T-Track . The T-Track is attached with 2 six
              inch lag bolts per rafter, penetrating the roof. The T-Track
              segments run parallel to and within 18” of the roof’s exterior
              party walls or interior load bearing walls/ columns. T-track can
              be segmented into multiple pieces in order to work around a
              specific project’s rooftop obstructions, such as hatches and
              skylights. With accurate and detailed site plans and measurements,
              a BSCC consultant can help your solar contractor determine T-Track
              lengths for your specific project.
            </GuideParagraph>

            <GuideHeading>III. Masonry Walls</GuideHeading>
            <GuideParagraph>
              With accurate wall measurements and material details, a BSCC
              consultant can help your solar contractor and/or professional
              engineer determine if masonry wall attachments are a viable option
              for your project.
            </GuideParagraph>
          </Wrapper>
        );

      default:
        return null;
    }
  },
  Environment: () => (
    <Wrapper>
      <GuideHeading>Summary</GuideHeading>
      <GuideParagraph>
        The canopy’s aluminum structure and robust engineering make it suitable
        for a range of climate conditions prevalent throughout North America.
        This includes Its built-in modularity and simplicity can accommodate a
        variety of residential uses, from amenity spaces to parking areas and
        beyond.
      </GuideParagraph>
    </Wrapper>
  ),
  Color: () => (
    <Wrapper>
      <GuideHeading>I.Intro</GuideHeading>
      <GuideParagraph>
        The Brooklyn Solar Canopy is available in standard aluminum mill finish,
        as well as a range of eight powder coat tones. Custom powder coat tones
        are available for an additional cost. Consult with a BSCC representative
        for full details.
      </GuideParagraph>
    </Wrapper>
  )
};
