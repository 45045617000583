import React from "react";
import styled from "styled-components";
import { Box, Heading } from "grommet";
import Arrow from "components/Arrow";

export const Label = styled(Heading)`
  margin-top: 0;
  margin-right: 20px;
`;
Label.defaultProps = {
  textAlign: "center",
  level: "5"
};

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding: 30px 35px; */
  z-index: 2;
  transition: background-color 0.2s 0.1s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

Wrapper.defaultProps = {
  pad: { vertical: "medium", horizontal: "medium" },
  border: "bottom"
};
export const BackButton = styled(Wrapper)`
  justify-content: flex-start;
  color: #66d566;
  font-weight: bold;
  text-transform: uppercase;
`;

BackButton.defaultProps = {
  children: (
    <React.Fragment>
      <Arrow direction="left" />
      <Box margin={{ left: "32px" }}>Back</Box>
    </React.Fragment>
  )
};
