import { consts } from "state";
import { TRANSLATE_X, ROTATE_Z } from "./consts/orientation";
import { transformNode, hideNodes, showNodes } from "./helpers";
import { TRANSLATE_Y as HEIGHT_TRANSLATE_Y } from "./consts/height";

export default async (sketchfab, { orientation, wide, moduleType, height }) => {
  const rotate = {
    z: ROTATE_Z[orientation]
  };

  const translate = {
    x: TRANSLATE_X[orientation],
    y: HEIGHT_TRANSLATE_Y[height]
  };

  transformNode(sketchfab, "truss_01", { rotate, translate });
  transformNode(sketchfab, "truss_02", { rotate, translate });

  hideNodes(sketchfab, ALL_TRUSS_NODES);
  showNodes(sketchfab, TRUSS_NODES[moduleType][wide]);
};

const ALL_TRUSS_NODES = [
  "truss_01_wide_2",
  "truss_01_wide_3",
  "truss_01_wide_4",
  "truss_01_wide_5",
  "truss72_01_wide_2",
  "truss72_01_wide_3",
  "truss_02_wide_2",
  "truss_02_wide_3",
  "truss_02_wide_4",
  "truss_02_wide_5",
  "truss72_02_wide_2",
  "truss72_02_wide_3"
];

const TRUSS_NODES = {
  [consts.moduleTypes[0]]: {
    [consts.size.wide[0]]: ["truss_01_wide_2", "truss_02_wide_2"],
    [consts.size.wide[1]]: ["truss_01_wide_3", "truss_02_wide_3"],
    [consts.size.wide[2]]: ["truss_01_wide_4", "truss_02_wide_4"],
    [consts.size.wide[3]]: ["truss_01_wide_5", "truss_02_wide_5"]
  },
  [consts.moduleTypes[1]]: {
    [consts.size.wide[0]]: ["truss72_01_wide_2", "truss72_02_wide_2"],
    [consts.size.wide[1]]: ["truss72_01_wide_3", "truss72_02_wide_3"],
    [consts.size.wide[2]]: ["truss_01_wide_5", "truss_02_wide_5"],
    [consts.size.wide[3]]: ["truss_01_wide_5", "truss_02_wide_5"]
  }
};
