import { consts } from "state";
import { ANNOTATIONS_NAMES, HIDDEN_ANNOTATIONS_NAMES } from "./names";

const front = {
  position: [160.16729569180046, -437.84834902820063, 100.26420661690003],
  target: [170.5738260609, 91.97566060859997, 77.93750372810003]
};

const side = {
  position: [-433.73227840144494, 129.28175358729612, 78.31730834944885],
  target: [161.0083153594, 125.0905855203, 43.9348711485]
};

const angle = {
  position: [-216.4295952651001, -280.4974101991003, 293.05604065850025],
  target: [161.0083153594, 125.0905855203, 43.9348711485]
};

const attachmentPlateTruck = {
  [consts.moduleTypes[0]]: {
    [consts.size.wide[0]]: {
      [consts.size.long[0]]: {
        position: [86.26310703707341, 90.70427230529957, 17.638517288309743],
        target: [148.2928680626074, 83.90260257148678, -51.59894816777441]
      },
      [consts.size.long[1]]: {
        position: [86.02617273163398, 80.60729992736677, 18.418142780453408],
        target: [148.05593375716796, 73.80563019355398, -50.819322675630744]
      },
      [consts.size.long[2]]: {
        position: [85.35638257599868, 66.16825386436717, 19.236524835996256],
        target: [147.38614360153267, 59.366584130554386, -50.000940620087896]
      }
    },
    [consts.size.wide[1]]: {
      [consts.size.long[0]]: {
        position: [47.07377807937175, 91.96497115143995, 15.545212759234687],
        target: [146.9780816482177, 88.09324717283508, -52.35493980117052]
      },
      [consts.size.long[1]]: {
        position: [47.478240860538904, 79.5583556455004, 16.847751264446778],
        target: [147.38254442938486, 75.68663166689552, -51.052401295958425]
      },
      [consts.size.long[2]]: {
        position: [46.62191686118737, 64.32944061649424, 16.456171918952386],
        target: [146.52622043003333, 60.45771663788937, -51.44398064145282]
      }
    },
    [consts.size.wide[2]]: {
      [consts.size.long[0]]: {
        position: [9.926858031338071, 90.71634132936443, 11.797888145381222],
        target: [146.9780816482177, 88.0932471728351, -52.35493980117052]
      },
      [consts.size.long[1]]: {
        position: [10.227560010245725, 80.62523402540343, 12.852892280376402],
        target: [147.27878362712536, 78.00213986887411, -51.29993566617534]
      },
      [consts.size.long[2]]: {
        position: [10.094845880703417, 65.7335078990435, 13.178267896788249],
        target: [147.14606949758306, 63.11041374251418, -50.97456004976349]
      }
    },
    [consts.size.wide[3]]: {
      [consts.size.long[0]]: {
        position: [-26.84801962549986, 89.9077552013691, 26.426349335823787],
        target: [109.04536484812317, 94.9292714909526, -106.79302133234528]
      },
      [consts.size.long[1]]: {
        position: [-25.75561270184393, 78.16706826744678, 27.098134699615656],
        target: [110.1377717717791, 83.18858455703028, -106.1212359685534]
      },
      [consts.size.long[2]]: {
        position: [-26.423800243683985, 62.41041961829135, 25.82261047567991],
        target: [109.46958422993904, 67.43193590787486, -107.39676019248915]
      }
    }
  },
  [consts.moduleTypes[1]]: {
    [consts.size.wide[0]]: {
      [consts.size.long[0]]: {
        position: [61.04924155890346, 87.85194901495413, 29.890698264000044],
        target: [142.95450070731232, 96.02907187339537, -58.56945934177241]
      },
      [consts.size.long[1]]: {
        position: [62.82382999634466, 76.21575328539603, 30.45815739049872],
        target: [144.72908914475352, 84.39287614383726, -58.00200021527373]
      },
      [consts.size.long[2]]: {
        position: [64.0076437977023, 61.474735925610155, 30.19161328223717],
        target: [145.91290294611116, 69.65185878405138, -58.26854432353528]
      }
    },
    [consts.size.wide[1]]: {
      [consts.size.long[0]]: {
        position: [15.782707484543081, 89.63722274297687, 21.861214492197064],
        target: [142.9545007073123, 96.02907187339537, -58.56945934177243]
      },
      [consts.size.long[1]]: {
        position: [17.284119167125297, 77.9074503260736, 23.3029813566151],
        target: [144.4559123898945, 84.2992994564921, -57.127692477354394]
      },
      [consts.size.long[2]]: {
        position: [17.818084624296617, 62.60364026446314, 22.931054840263684],
        target: [144.98987784706583, 68.99548939488163, -57.49961899370581]
      }
    },
    [consts.size.wide[2]]: {
      [consts.size.long[0]]: {
        position: [-20.416050605437306, 89.21847893107274, 31.70460645201618],
        target: [104.70824655170003, 91.73185500109999, -111.74301985259996]
      },
      [consts.size.long[1]]: {
        position: [-20.21754477071373, 78.37218337506246, 31.687715850826265],
        target: [104.9067523864236, 80.88555944508971, -111.75991045378987]
      },
      [consts.size.long[2]]: {
        position: [-18.328182760971856, 63.683337173942164, 33.07837322275476],
        target: [106.79611439616548, 66.19671324396941, -110.36925308186137]
      }
    }
  }
};

const LegAssembly = {
  [consts.moduleTypes[0]]: {
    [consts.size.wide[0]]: {
      [consts.size.long[0]]: {
        position: [94.21419606610613, 74.18265102377886, 95.47006001374397],
        target: [238.19031805644755, 178.83995229738966, 78.94882583495726]
      },
      [consts.size.long[1]]: {
        position: [91.45526940039801, 78.45136294955269, 97.55479844327436],
        target: [238.19031805644755, 178.83995229738966, 78.94882583495726]
      },
      [consts.size.long[2]]: {
        position: [82.95449033335106, 94.76921232512083, 105.33024948960286],
        target: [236.0107958196953, 182.31139445131495, 81.94573317142668]
      }
    },
    [consts.size.wide[1]]: {
      [consts.size.long[0]]: {
        position: [106.1989036819858, 55.6959788503831, 96.53058807699863],
        target: [254.695336999676, 153.13712975227352, 76.30048686906771]
      },
      [consts.size.long[1]]: {
        position: [95.48667817940597, 73.9287312836287, 94.57227278962172],
        target: [254.695336999676, 153.13712975227352, 76.30048686906771]
      },
      [consts.size.long[2]]: {
        position: [90.3906744296585, 85.11088528859727, 94.51129448334353],
        target: [254.695336999676, 153.13712975227352, 76.30048686906771]
      }
    },
    [consts.size.wide[2]]: {
      [consts.size.long[0]]: {
        position: [142.06221610368905, 59.31705970008588, 73.64307299427628],
        target: [265.251193779829, 135.00894189474684, 69.0569568635561]
      },
      [consts.size.long[1]]: {
        position: [135.184107093725, 71.86913640668904, 73.71910616909472],
        target: [265.251193779829, 135.00894189474684, 69.0569568635561]
      },
      [consts.size.long[2]]: {
        position: [125.3385397161567, 95.77682959173896, 85.51797424869311],
        target: [257.6377545395842, 153.5933380924729, 76.57443948236312]
      }
    },
    [consts.size.wide[3]]: {
      [consts.size.long[0]]: {
        position: [142.36045625583023, 66.87241894700587, 84.33181627517719],
        target: [269.317876431873, 130.2455964232043, 77.01995771627419]
      },
      [consts.size.long[1]]: {
        position: [137.69652303419866, 76.17498430096727, 83.97789743083793],
        target: [264.65394321024144, 139.5481617771657, 76.66603887193493]
      },
      [consts.size.long[2]]: {
        position: [135.38311903782022, 80.85486388297845, 84.37109925356377],
        target: [262.340539213863, 144.22804135917687, 77.05924069466077]
      }
    }
  },
  [consts.moduleTypes[1]]: {
    [consts.size.wide[0]]: {
      [consts.size.long[0]]: {
        position: [86.56204429693145, 83.58932370127216, 94.70050301573697],
        target: [257.78885395636826, 148.19253044574833, 75.49234981509746]
      },
      [consts.size.long[1]]: {
        position: [82.0567074346637, 94.88877862855115, 92.54238639893944],
        target: [253.28351709410052, 159.49198537302732, 73.33423319829993]
      },
      [consts.size.long[2]]: {
        position: [70.22848867238699, 70.66318142669911, 102.9311487471329],
        target: [247.56529078906826, 174.5217210037581, 72.91021904299049]
      }
    },
    [consts.size.wide[1]]: {
      [consts.size.long[0]]: {
        position: [120.13559509671595, 56.74290528771034, 108.98157424341083],
        target: [265.49119685454156, 143.6553565458471, 72.01708621129356]
      },
      [consts.size.long[1]]: {
        position: [128.7397963914073, 66.485034315485, 117.19623609806678],
        target: [263.4013457306652, 150.51886903762332, 79.16000718969524]
      },
      [consts.size.long[2]]: {
        position: [119.00917427231539, 83.01551761152163, 119.2673996399139],
        target: [253.67072361157327, 167.04935233365995, 81.23117073154236]
      }
    },
    [consts.size.wide[2]]: {
      [consts.size.long[0]]: {
        position: [165.13531559289152, 59.522536031316506, 113.72344145757904],
        target: [277.314245804517, 129.52637697154415, 82.03760768274289]
      },
      [consts.size.long[1]]: {
        position: [161.82416055799155, 66.7273387370543, 117.91844020632196],
        target: [274.003090769617, 136.73117967728194, 86.2326064314858]
      },
      [consts.size.long[2]]: {
        position: [156.01766764216939, 76.0964838113412, 118.06077441093939],
        target: [268.19659785379486, 146.10032475156885, 86.37494063610323]
      }
    }
  }
};

const Rail = {
  [consts.moduleTypes[0]]: {
    [consts.size.wide[0]]: {
      [consts.size.long[0]]: {
        position: [153.94543851727173, -15.586095210642384, 131.6906982661938],
        target: [157.95450993613875, 71.82645887000943, 131.597223534168]
      },
      [consts.size.long[1]]: {
        position: [154.36771362246398, -26.529351105971315, 134.1793884785561],
        target: [154.87367413388267, 71.8345346872739, 134.1793884785561]
      },
      [consts.size.long[2]]: {
        position: [154.24415223374797, -50.550945783461856, 134.1793884785561],
        target: [154.87367413388267, 71.8345346872739, 134.1793884785561]
      }
    },
    [consts.size.wide[1]]: {
      [consts.size.long[0]]: {
        position: [148.33958741985273, -14.924240166964026, 132.3890086462048],
        target: [153.28506117282427, 72.12633767731317, 132.3890086462048]
      },
      [consts.size.long[1]]: {
        position: [147.00101042773105, -38.48596680300824, 132.3890086462048],
        target: [153.28506117282427, 72.12633767731317, 132.3890086462048]
      },
      [consts.size.long[2]]: {
        position: [147.89377955797062, -60.52108398399072, 132.83898769754464],
        target: [155.42277874225488, 72.00489073930673, 132.83898769754464]
      }
    },
    [consts.size.wide[2]]: {
      [consts.size.long[0]]: {
        position: [154.76910363400302, -12.96087057571178, 131.8493495491209],
        target: [154.55117004813576, 72.00265504102562, 131.8493495491209]
      },
      [consts.size.long[1]]: {
        position: [154.76721537291075, -37.08782829215782, 133.56214635122234],
        target: [154.48739595164534, 72.00249145885988, 133.56214635122234]
      },
      [consts.size.long[2]]: {
        position: [154.8131399553427, -54.9919728982549, 133.56214635122234],
        target: [154.48739595164534, 72.00249145885988, 133.56214635122234]
      }
    },
    [consts.size.wide[3]]: {
      [consts.size.long[0]]: {
        position: [148.92314478753192, -11.041867867177984, 133.5314277096965],
        target: [148.71017192200316, 71.98767273353032, 133.5314277096965]
      },
      [consts.size.long[1]]: {
        position: [148.98193737583532, -33.96272957148794, 133.5314277096965],
        target: [148.71017192200316, 71.98767273353032, 133.5314277096965]
      },
      [consts.size.long[2]]: {
        position: [149.019692108866, -48.68177902061829, 133.5314277096965],
        target: [148.71017192200316, 71.98767273353032, 133.5314277096965]
      }
    }
  },
  [consts.moduleTypes[1]]: {
    [consts.size.wide[0]]: {
      [consts.size.long[0]]: {
        position: [154.81221624678219, -10.153292264012919, 134.07743557675485],
        target: [155.47714860204684, 72.00503019936822, 134.07743557675485]
      },
      [consts.size.long[1]]: {
        position: [154.659490423146, -29.023929806972504, 134.07743557675485],
        target: [155.47714860204684, 72.00503019936822, 134.07743557675485]
      },
      [consts.size.long[2]]: {
        position: [154.51018115810894, -47.47242106561119, 134.07743557675485],
        target: [155.47714860204684, 72.00503019936822, 134.07743557675485]
      }
    },
    [consts.size.wide[1]]: {
      [consts.size.long[0]]: {
        position: [143.72595261033018, -12.83120596032785, 134.48651820667374],
        target: [146.36421277316467, 72.07878396773147, 134.48651820667374]
      },
      [consts.size.long[1]]: {
        position: [143.25819647176337, -27.885509388780733, 134.48651820667374],
        target: [146.3642127731647, 72.07878396773147, 134.48651820667374]
      },
      [consts.size.long[2]]: {
        position: [143.25418654331483, -46.36919091357137, 134.41847198580615],
        target: [146.93396402225324, 72.06108107995023, 134.41847198580615]
      }
    },
    [consts.size.wide[2]]: {
      [consts.size.long[0]]: {
        position: [153.60009679501385, -6.274303029707639, 135.14384582296253],
        target: [156.0252987033918, 71.77860187889777, 135.14384582296253]
      },
      [consts.size.long[1]]: {
        position: [153.62330356850723, -31.804262132313625, 135.17958536841437],
        target: [156.8409659466123, 71.75325807193538, 135.17958536841437]
      },
      [consts.size.long[2]]: {
        position: [154.17131802565737, -50.95655216038432, 135.17958536841437],
        target: [156.8409659466123, 71.75325807193538, 135.17958536841437]
      }
    }
  }
};

const Truss = {
  [consts.moduleTypes[0]]: {
    [consts.size.wide[0]]: {
      [consts.size.long[0]]: {
        position: [195.03242215630047, -153.22085414760073, 33.67434405750044],
        target: [174.7606721192, 119.7863395169, 95.82070071219997]
      },
      [consts.size.long[1]]: {
        position: [176.01511746031753, -160.84577187000218, 88.7435746711093],
        target: [174.7606721192, 119.7863395169, 95.82070071219997]
      },
      [consts.size.long[2]]: {
        position: [178.85585972341713, -160.6209527789602, 83.13128963932976],
        target: [174.7606721192, 119.7863395169, 95.82070071219997]
      }
    },
    [consts.size.wide[1]]: {
      [consts.size.long[0]]: {
        position: [175.75843671150758, -159.14311420539985, 64.14429650306306],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      },
      [consts.size.long[1]]: {
        position: [178.09221655614522, -159.9888417066802, 73.00011670125889],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      },
      [consts.size.long[2]]: {
        position: [175.3025401923368, -160.39317735056858, 78.35144261329609],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      }
    },
    [consts.size.wide[2]]: {
      [consts.size.long[0]]: {
        position: [195.67979829589592, -161.93930927816183, 31.689706895565664],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      },
      [consts.size.long[1]]: {
        position: [196.7288569318979, -176.06737191114877, 28.47364651472259],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      },
      [consts.size.long[2]]: {
        position: [197.44928181432593, -185.76960161691204, 48.2489538240342],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      }
    },
    [consts.size.wide[3]]: {
      [consts.size.long[0]]: {
        position: [195.03242215629993, -153.2208541476, 33.67434405750001],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      },
      [consts.size.long[1]]: {
        position: [197.41953757607402, -185.48811437713755, 27.214142887111606],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      },
      [consts.size.long[2]]: {
        position: [197.3098504187468, -183.89182717977098, 26.69251620373882],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      }
    }
  },
  [consts.moduleTypes[1]]: {
    [consts.size.wide[0]]: {
      [consts.size.long[0]]: {
        position: [195.0324221563, -153.22085414760073, 33.674344057499844],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      },
      [consts.size.long[1]]: {
        position: [192.50823098308106, -156.20713435080796, 47.66815327809267],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      },
      [consts.size.long[2]]: {
        position: [184.3336653248478, -157.89294685475926, 55.71318260403543],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      }
    },
    [consts.size.wide[1]]: {
      [consts.size.long[0]]: {
        position: [195.03242215630019, -153.22085414759994, 33.6743440574997],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      },
      [consts.size.long[1]]: {
        position: [195.88527410241338, -164.7065283490649, 31.05978729156658],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      },
      [consts.size.long[2]]: {
        position: [193.23128586423866, -167.734639728567, 45.15572756274827],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      }
    },
    [consts.size.wide[2]]: {
      [consts.size.long[0]]: {
        position: [195.0324221563, -153.2208541476005, 33.674344057500456],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      },
      [consts.size.long[1]]: {
        position: [196.22258474748958, -169.24921619974168, 52.15091391691198],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      },
      [consts.size.long[2]]: {
        position: [206.3951346932078, -179.1191687840705, 30.53844715205115],
        target: [174.7606721192, 119.7863395169, 95.82070071220004]
      }
    }
  }
};

export default {
  [ANNOTATIONS_NAMES[0]]: Truss,
  [ANNOTATIONS_NAMES[1]]: Rail,
  [ANNOTATIONS_NAMES[2]]: LegAssembly,
  [ANNOTATIONS_NAMES[3]]: attachmentPlateTruck,
  [HIDDEN_ANNOTATIONS_NAMES[0]]: front,
  [HIDDEN_ANNOTATIONS_NAMES[1]]: side,
  [HIDDEN_ANNOTATIONS_NAMES[2]]: angle
};
