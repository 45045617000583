import React from "react";
import styled from "styled-components";
import { Box } from "grommet";

export const RadioContainer = styled(Box)`
  align-items: flex-start;
  justify-content: flex-start;
  margin: 8px 0;
  flex-direction: row;
`;

export const RadioIcon = styled(Box)`
  border-radius: 50%;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  border: 1px solid white;
  margin-right: 12px;
  cursor: pointer;
`;

RadioIcon.defaultProps = {
  justify: "center",
  align: "center"
};

const RadioIconInner = styled(Box)`
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: white;
`;

export const ActiveRadioIcon = styled(RadioIcon)``;
ActiveRadioIcon.defaultProps = {
  children: <RadioIconInner />
};
