import React from "react";
import { useGlobalState, changeOrientation } from "state";
import { selectOrientation } from "state/selectors";
import RadioButtonGroup from "components/RadioButtonGroup";
import options from "./options";

export default () => {
  const [orientation, dispatch] = useGlobalState(selectOrientation);

  const onChange = event => {
    dispatch(changeOrientation(event.target.value));
  };

  return (
    <RadioButtonGroup
      name="orientation"
      options={options}
      value={orientation}
      onChange={onChange}
    />
  );
};
