import React from "react";
import { useGlobalState, changeLegsColor, changeTrussColor } from "state";
import { selectLegsColor, selectTrussColor } from "state/selectors";
import RadioButtonGroup from "components/RadioButtonGroup";
import options from "./options";
import Container from "./Container";
import { Text, Box } from "grommet";

export default () => {
  const [state, dispatch] = useGlobalState();

  const legColor = selectLegsColor(state);
  const trussColor = selectTrussColor(state);

  const onLegChange = event => {
    dispatch(changeLegsColor(event.target.value));
  };

  const onTrussChange = event => {
    dispatch(changeTrussColor(event.target.value));
  };

  return (
    <Container>
      <Box margin={{ bottom: "medium" }}>
        <Text margin={{ bottom: "small" }}>Truss and Leg</Text>
        <RadioButtonGroup
          name={"legs_color"}
          options={options}
          value={legColor}
          onChange={onLegChange}
        />
      </Box>
      <Box margin={{ bottom: "medium" }}>
        <Text margin={{ bottom: "small" }}>Rail</Text>
        <RadioButtonGroup
          name={"truss_color"}
          options={options}
          value={trussColor}
          onChange={onTrussChange}
        />
      </Box>
    </Container>
  );
};
