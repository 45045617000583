import { useState } from "react";
import { useGlobalState } from "state";
import { selectSize, selectModuleType } from "state/selectors";
import {
  ANNOTATIONS_POINTS,
  HIDDEN_ANNOTATIONS_NAMES,
  MANUAL_ANNOTATIONS_POINTS
} from "../Model/handlers/consts/annotations";

let hideAnnotation = null;
let timeoutOnExpectMovingCamera = null;

const useCameraMove = sketchfab => {
  const [state] = useGlobalState();
  const { wide, long } = selectSize(state);
  const moduleType = selectModuleType(state);

  return (name, cb = () => {}) => {
    const points = { ...ANNOTATIONS_POINTS, ...MANUAL_ANNOTATIONS_POINTS };
    const { position, target } = HIDDEN_ANNOTATIONS_NAMES.includes(name)
      ? points[name]
      : points[name][moduleType][wide][long];
    sketchfab && sketchfab.lookat(position, target, 1, cb);
  };
};

export const useAnnotations = sketchfab => {
  const [hoveredAnnotation, setHoveredAnnotation] = useState("");
  const [activeAnnotation, setActiveAnnotation] = useState("");

  const moveCameraTo = useCameraMove(sketchfab);

  hideAnnotation = () => {
    sketchfab && sketchfab.removeEventListener("camerastart", hideAnnotation);
    setHoveredAnnotation("");
    setActiveAnnotation("");
  };

  const resetExpectMovingCamera = () => {
    if (timeoutOnExpectMovingCamera) {
      clearTimeout(timeoutOnExpectMovingCamera);
      timeoutOnExpectMovingCamera = null;
    }
    sketchfab && sketchfab.removeEventListener("camerastart", hideAnnotation);
  };

  const highlightAnnotation = (name = "") =>
    !activeAnnotation && setHoveredAnnotation(name);

  const activateAnnotation = name => {
    if (activeAnnotation) {
      resetExpectMovingCamera();
    }
    if (HIDDEN_ANNOTATIONS_NAMES.includes(name)) {
      hideAnnotation();
      moveCameraTo(name);
    } else {
      setHoveredAnnotation(name);
      setActiveAnnotation(name);
      moveCameraTo(name, () => {
        timeoutOnExpectMovingCamera = setTimeout(() => {
          sketchfab &&
            sketchfab.addEventListener("camerastart", hideAnnotation);
        }, 1000);
      });
    }
  };

  return {
    hoveredAnnotation,
    activeAnnotation,
    highlightAnnotation,
    activateAnnotation,
    hideAnnotation
  };
};
