import styled from "styled-components";
import { Table } from "grommet";

export const StyledTable = styled(Table)`
  td {
    padding: 0px 6px;
    & > div {
      padding: 0px;
    }
    &:last-child {
      width: 100%;
    }
  }
`;
