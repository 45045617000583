import { consts } from "state";
import { transformNode, hideNodes, showNodes, scaleNode } from "./helpers";
import { SCALE_Y as HEIGHT_SCALE_Y } from "./consts/height";
import range from "lodash/range";

export default async (sketchfab, { orientation, moduleType, wide, height }) => {
  // transformNode(sketchfab, "leg_01_02", {
  //   translate: { x: TRANSLATE_X[moduleType][wide] },
  //   scale: { y: SCALE_Y[orientation][moduleType][wide] }
  // });
  // transformNode(sketchfab, "leg_02_02", {
  //   translate: { x: TRANSLATE_X[moduleType][wide] },
  //   scale: { y: SCALE_Y[orientation][moduleType][wide] }
  // });

  switch (orientation) {
    case consts.orientation.lateral:
      const [scale1, scale2] = LATERAL_WIDE_SCALE_Y[moduleType][wide][height];

      ["leg_01_02", "leg_02_02"].forEach(nodeName => {
        transformNode(sketchfab, nodeName, {
          translate: { x: TRANSLATE_X[moduleType][wide] },
          scale: { y: scale1 }
        });
      });
      ["leg_01_01", "leg_02_01"].forEach(nodeName => {
        scaleNode(sketchfab, nodeName, [1, scale2, 1]);
      });

      break;

    default:
      ["leg_01_02", "leg_02_02"].forEach(nodeName => {
        // scaleNode(sketchfab, nodeName, [1, OTHER_SCALE_Y[height], 1]);
        transformNode(sketchfab, nodeName, {
          translate: { x: TRANSLATE_X[moduleType][wide] },
          scale: { y: OTHER_SCALE_Y[height] }
        });
      });
      ["leg_01_01", "leg_02_01"].forEach(nodeName => {
        scaleNode(sketchfab, nodeName, [1, OTHER_SCALE_Y[height], 1]);
      });
      break;
  }

  hideNodes(sketchfab, [...SMALL_LEGS, ...LARGE_LEGS]);
  showNodes(sketchfab, LEG_NODES[moduleType][wide]);
};

const SMALL_LEGS = range(1, 9).map(n => `leg_small_0${n}`);
const LARGE_LEGS = range(1, 9).map(n => `leg_large_0${n}`);

const LEG_NODES = {
  [consts.moduleTypes[0]]: {
    [consts.size.wide[0]]: SMALL_LEGS,
    [consts.size.wide[1]]: SMALL_LEGS,
    [consts.size.wide[2]]: LARGE_LEGS,
    [consts.size.wide[3]]: LARGE_LEGS
  },
  [consts.moduleTypes[1]]: {
    [consts.size.wide[0]]: SMALL_LEGS,
    [consts.size.wide[1]]: LARGE_LEGS,
    [consts.size.wide[2]]: LARGE_LEGS,
    [consts.size.wide[3]]: LARGE_LEGS
  }
};

const TRANSLATE_X = {
  [consts.moduleTypes[0]]: {
    [consts.size.wide[0]]: -203.0,
    [consts.size.wide[1]]: -136.0,
    [consts.size.wide[2]]: -68.0,
    [consts.size.wide[3]]: 0
  },
  [consts.moduleTypes[1]]: {
    [consts.size.wide[0]]: -172.0,
    [consts.size.wide[1]]: -88.0,
    [consts.size.wide[2]]: 0
  }
};

const OTHER_SCALE_Y = {
  [consts.size.height[0]]: 0.92,
  [consts.size.height[1]]: 1,
  [consts.size.height[2]]: 1.077,
  [consts.size.height[3]]: 1.16
};

const LATERAL_WIDE_SCALE_Y = {
  [consts.moduleTypes[0]]: {
    [consts.size.wide[0]]: {
      [consts.size.height[0]]: [0.831, 0.895],
      [consts.size.height[1]]: [0.906, 1],
      [consts.size.height[2]]: [0.987, 1.083],
      [consts.size.height[3]]: [1.065, 1.15]
    },
    [consts.size.wide[1]]: {
      [consts.size.height[0]]: [0.783, 0.895],
      [consts.size.height[1]]: [0.863, 1],
      [consts.size.height[2]]: [0.943, 1.083],
      [consts.size.height[3]]: [1.026, 1.15]
    },
    [consts.size.wide[2]]: {
      [consts.size.height[0]]: [0.731, 0.895],
      [consts.size.height[1]]: [0.814, 1],
      [consts.size.height[2]]: [0.906, 1.083],
      [consts.size.height[3]]: [0.983, 1.15]
    },
    [consts.size.wide[3]]: {
      [consts.size.height[0]]: [0.697, 0.895],
      [consts.size.height[1]]: [0.775, 1],
      [consts.size.height[2]]: [0.856, 1.083],
      [consts.size.height[3]]: [0.936, 1.15]
    }
  },
  [consts.moduleTypes[1]]: {
    [consts.size.wide[0]]: {
      [consts.size.height[0]]: [0.813, 0.895],
      [consts.size.height[1]]: [0.893, 1],
      [consts.size.height[2]]: [0.969, 1.083],
      [consts.size.height[3]]: [1.048, 1.15]
    },
    [consts.size.wide[1]]: {
      [consts.size.height[0]]: [0.755, 0.895],
      [consts.size.height[1]]: [0.833, 1],
      [consts.size.height[2]]: [0.913, 1.083],
      [consts.size.height[3]]: [0.991, 1.15]
    },
    [consts.size.wide[2]]: {
      [consts.size.height[0]]: [0.697, 0.895],
      [consts.size.height[1]]: [0.766, 1],
      [consts.size.height[2]]: [0.856, 1.083],
      [consts.size.height[3]]: [0.936, 1.15]
    }
  }
};
