import { consts } from "state";

export const TRANSLATE_Y = {
  [consts.size.height[0]]: -10,
  [consts.size.height[1]]: 0,
  [consts.size.height[2]]: 10,
  [consts.size.height[3]]: 20
};

export const SCALE_Y = {
  [consts.size.height[0]]: 0.92,
  [consts.size.height[1]]: 1,
  [consts.size.height[2]]: 1.077,
  [consts.size.height[3]]: 1.16
};
