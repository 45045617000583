import styled from "styled-components";
import { Box } from "grommet";
import { iconSizes, annotations } from "../consts";

const { width: iconWidth, space } = iconSizes;

const iconsQuantity = annotations.length + 1; // + home icon

export const Container = styled(Box)`
  font-size: 11px;
  line-height: 1.3;
  padding: 10px;
  width: ${iconsQuantity * iconWidth + 2 * space}px;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 50px;
  right: 0;
  &:after {
    content: " ";
    position: absolute;
    bottom: -8px;
    left: ${({ annIndex }) => annIndex * iconWidth - iconWidth / 2 - 3}px;
    transform: rotate(45deg) skew(15deg, 15deg);
    border-style: solid;
    border-width: 8px;
    border-color: transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) transparent;
  }
`;

export const CloseIcon = styled.span`
  font-size: 16px;
  font-weight: bold;
  line-height: 0.6;
  cursor: pointer;
  position: absolute;
  right: 3px;
  top: 3px;
  color: white;
`;
