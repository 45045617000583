import React from "react";
import { Text } from "grommet";

const arrows = {
  right: "›",
  left: "‹"
};

export default ({ direction = "" }) => (
  <Text size="30px" style={{ lineHeight: "15px" }}>
    {arrows[direction]}
  </Text>
);
