import React, { useState, useCallback } from "react";
import Swipe from "react-easy-swipe";
import { SwipeLayer, SwipeBox } from "./SwipeLayer.style";
import theme from "components/theme";

const extractNumber = width => {
  return parseInt(width.substr(0, width.indexOf("px")));
};

export default function({ onClose, children }) {
  const [swipeTo, setSwipeTo] = useState(0);

  const onSwipeLeft = useCallback(() => {
    setSwipeTo(extractNumber(theme.sider.width) * -1);
    setTimeout(() => {
      setSwipeTo(0);
      onClose();
    }, 200);
  }, [setSwipeTo, onClose]);

  return (
    <SwipeLayer style={{ zIndex: 9999 }}>
      <SwipeBox
        swipeTo={swipeTo}
        overflow={{ vertical: "auto", horizontal: "hidden" }}
      >
        <Swipe style={{ height: "100%" }} onSwipeLeft={onSwipeLeft}>
          {children}
        </Swipe>
      </SwipeBox>
    </SwipeLayer>
  );
}
