import { useEffect, useState, useRef } from "react";
import SketchfabWrapper from "sketchfab-api-wrapper";
import { useGlobalState, changeMeasurements } from "state";
import handleAttachments from "./handlers/attachments";
import handleSupport from "./handlers/support";
import handleGround from "./handlers/ground";
import handleColor from "./handlers/color";
import handleModules from "./handlers/modules";
import handleTruss from "./handlers/truss";
import handleLegs from "./handlers/legs";
import handleScrew from "./handlers/screw";
import handlePads from "./handlers/pads";
import handleBeams from "./handlers/beams";
import handleRails from "./handlers/rails";
import handleBracing from "./handlers/bracing";
import handleUtilization from "./handlers/utilization";
import handleSetting from "./handlers/setting";
import handleRailBolts from "./handlers/railBolts";
import handleCanopy from "./handlers/canopy";
import handleAnnotationPlate from "./handlers/annotationPlate";
import handleRooftop from "./handlers/rooftop";
import {
  HIDDEN_ANNOTATIONS_NAMES,
  ANNOTATIONS_POINTS
} from "./handlers/consts/annotations";
import consts from "state/consts";
import {
  selectModuleType,
  selectAttachments,
  selectSize,
  selectOrientation,
  selectTrussColor,
  selectLegsColor,
  selectSnowBrace,
  selectUtilization,
  selectSetting
} from "state/selectors";

// mock window.getDimensions
window.getDimensions = params =>
  new Promise((resolve, reject) => {
    resolve(consts.measurements);
  });

export const useDimensions = sketchfab => {
  const [state, dispatch] = useGlobalState();
  const moduleType = selectModuleType(state);
  const { long, wide, height } = selectSize(state);
  const orientation = selectOrientation(state);

  const validateMeasurements = measurements => {
    const measurementNames = Object.keys(measurements);
    Object.keys(consts.measurements).forEach(expectedKey => {
      if (!measurementNames.includes(expectedKey)) {
        throw new Error(`wrong measurements ! not found "${expectedKey}"`);
      }
    });
  };

  useEffect(() => {
    sketchfab &&
      window.getDimensions &&
      window
        .getDimensions({
          height,
          module_type: moduleType,
          panels_wide: wide,
          panels_long: long,
          orientation
        })
        .then(measurements => {
          validateMeasurements(measurements);
          dispatch(changeMeasurements(measurements));
        });
  }, [sketchfab, moduleType, long, wide, height, orientation, dispatch]);
};

export const useSketchfab = (uid, frameId, options = {}, init, setInit) => {
  const [isLoading, setIsLoading] = useState(true);

  const sketchFabApiRef = useRef();

  useEffect(() => {
    const init = async () => {
      const loadWrapperPromise = new SketchfabWrapper({
        iframeQuery: `#${frameId}`,
        uid,
        options,
        useSingleton: false
      });

      !init && window.onLoadStart && window.onLoadStart();

      const wrapper = await loadWrapperPromise.init();

      !init && window.onLoadSuccess && window.onLoadSuccess();

      const api = { ...wrapper, ...wrapper.api };

      sketchFabApiRef.current = api;
      window.api = api;

      // Hide annotations used for screenshots
      HIDDEN_ANNOTATIONS_NAMES.forEach(name => {
        const index = api.annotations.findIndex(a => a.name === name);
        if (index !== -1) {
          api.hideAnnotation(index);
        }
      });

      // Adding here custom functions for making three screenshots
      const getCanopyScreenshot = async ({
        aspect = HIDDEN_ANNOTATIONS_NAMES[0], // front
        width = 700,
        height = 500,
        mimetype = "image/png"
      }) =>
        new Promise((res, rej) => {
          api.lookat(
            ANNOTATIONS_POINTS[aspect].position,
            ANNOTATIONS_POINTS[aspect].target,
            0
          );
          setTimeout(() => {
            api.getScreenShot(width, height, mimetype, (err, result) => {
              if (err) {
                rej(err);
              }

              res(result);
            });
          }, 100);
        });

      window.getCanopyScreenshot = getCanopyScreenshot;

      setIsLoading(false);
      !init && setInit(true);
    };

    init();
  }, [frameId, options, uid, init, setInit]);

  return [isLoading, sketchFabApiRef.current];
};

export const useHandler = (sketchfab, selector, handler) => {
  const [state] = useGlobalState(selector);
  useEffect(() => {
    if (sketchfab) {
      handler(sketchfab, state);
    }
  }, [handler, sketchfab, state]);
};

export const useModelHandler = sketchfab => {
  const [state] = useGlobalState();

  const orientation = selectOrientation(state);
  const moduleType = selectModuleType(state);
  const attachments = selectAttachments(state);
  const legsColor = selectLegsColor(state);
  const trussColor = selectTrussColor(state);
  const { long, wide, height } = selectSize(state);
  const snowBrace = selectSnowBrace(state);
  const utilization = selectUtilization(state);
  const setting = selectSetting(state);

  useEffect(() => {
    sketchfab && handleRooftop(sketchfab, { moduleType, wide, long });
  }, [sketchfab, moduleType, wide, long]);

  useEffect(() => {
    sketchfab && handleColor(sketchfab, legsColor, "legs");
  }, [sketchfab, legsColor]);

  useEffect(() => {
    sketchfab && handleColor(sketchfab, trussColor, "truss");
  }, [sketchfab, trussColor]);

  useEffect(() => {
    sketchfab && handleAttachments(sketchfab, attachments);
  }, [sketchfab, attachments]);

  useEffect(() => {
    sketchfab && handleSupport(sketchfab, { moduleType, orientation, long });
  }, [sketchfab, orientation, moduleType, long]);

  useEffect(() => {
    sketchfab && handleGround(sketchfab, { moduleType, wide });
  }, [sketchfab, moduleType, wide]);

  useEffect(() => {
    sketchfab &&
      handleModules(sketchfab, { orientation, moduleType, long, wide, height });
  }, [sketchfab, orientation, moduleType, long, wide, height]);

  useEffect(() => {
    sketchfab &&
      handleTruss(sketchfab, { orientation, wide, moduleType, height });
  }, [sketchfab, orientation, wide, moduleType, height]);

  useEffect(() => {
    sketchfab &&
      handleLegs(sketchfab, { orientation, moduleType, wide, height });
  }, [sketchfab, orientation, moduleType, wide, height]);

  useEffect(() => {
    sketchfab && handleAnnotationPlate(sketchfab, { moduleType, wide, long });
  }, [sketchfab, moduleType, wide, long]);

  useEffect(() => {
    sketchfab && handleScrew(sketchfab, { long });
  }, [sketchfab, long]);

  useEffect(() => {
    sketchfab && handlePads(sketchfab, { long });
  }, [sketchfab, long]);

  useEffect(() => {
    sketchfab && handleBeams(sketchfab, { long });
  }, [sketchfab, long]);

  useEffect(() => {
    sketchfab && handleRails(sketchfab, { long, moduleType, wide });
  }, [sketchfab, long, moduleType, wide]);

  useEffect(() => {
    sketchfab &&
      handleBracing(sketchfab, {
        snowBrace,
        orientation,
        moduleType,
        wide,
        long,
        height
      });
  }, [sketchfab, long, moduleType, wide, orientation, snowBrace, height]);

  useEffect(() => {
    sketchfab && handleUtilization(sketchfab, { utilization });
  }, [sketchfab, utilization]);

  useEffect(() => {
    sketchfab && handleSetting(sketchfab, { setting });
  }, [sketchfab, setting]);

  useEffect(() => {
    sketchfab && handleRailBolts(sketchfab, { long });
  }, [sketchfab, long]);

  useEffect(() => {
    sketchfab && handleCanopy(sketchfab, { long, wide, moduleType });
  }, [sketchfab, long, moduleType, wide]);
};
