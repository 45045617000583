import React from "react";
import { Box, Button, Text } from "grommet";

import Container from "./Container";
import FooterDimensions from "./FooterDimensions";
import theme from "components/theme";
import { FooterBox, MoreInfoLink } from "./Footer.style";

export default () => {
  return (
    <Box direction="row">
      <FooterBox flex={false} width={theme.sider.width}>
        <h1>Product line</h1>
      </FooterBox>
      <FooterBox flex="grow">
        <FooterDimensions />
      </FooterBox>
      <FooterBox flex="grow">
        <h1>Price</h1>
      </FooterBox>
      <FooterBox flex="grow">
        <Box align="center" direction="row" gap="small">
          <Text>
            Solar <br />
            stats
          </Text>
          <Box>
            <Box background="status-warning" pad="12px 20px">
              <Text weight="bold" color="">
                7.2 KW | 8,500 KWH/Yr
              </Text>
            </Box>
            <MoreInfoLink />
          </Box>
        </Box>
      </FooterBox>
      <FooterBox flex="grow">
        <Button primary label="SUMMARY" wide color="#149BB7" />
      </FooterBox>
    </Box>
  );
  // return (
  //   <Container>
  //     <FooterDimensions />
  //     <div className="sectorWrapper">
  //       <div className="sector">
  //         <div className="left">
  //           <div className="title">Federal Tax Credit:</div>
  //         </div>
  //         <div className="right">
  //           <div className="priceBox box">$9,650</div>
  //           <div className="moreInfo">More info</div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="sectorWrapper">
  //       <div className="sector">
  //         <div className="left">
  //           <div className="title">Solar Stats:</div>
  //         </div>
  //         <div className="right">
  //           <div className="statsBox box">7.2 KW | 8,500 KWH/Yr</div>
  //           <div className="moreInfo">More info</div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="sectorWrapper">
  //       <Button
  //         primary
  //         label="SUMMARY"
  //         wide
  //         color="#149BB7"
  //         className="button"
  //       />
  //     </div>
  //   </Container>
  // );
};
