import { consts } from "state";
import { translateNode } from "./helpers";

export default async (sketchfab, { long, wide, moduleType }) => {
  // eslint-disable-next-line
  console.log('translateNode', `${moduleType}: ${wide}x${long}`, [
    TRANSLATE_X[moduleType][wide][long],
    0,
    TRANSLATE_Z[long]
  ]);
  translateNode(sketchfab, NODE_NAME, [
    TRANSLATE_X[moduleType][wide][long],
    0,
    TRANSLATE_Z[long]
  ]);
};

const NODE_NAME = "canopy";

const TRANSLATE_X = {
  [consts.moduleTypes[0]]: {
    [consts.size.wide[0]]: {
      [consts.size.long[0]]: 101.5,
      [consts.size.long[1]]: 101.5,
      [consts.size.long[2]]: 101.5
    },
    [consts.size.wide[1]]: {
      [consts.size.long[0]]: 67.75,
      [consts.size.long[1]]: 67.75,
      [consts.size.long[2]]: 67.75
    },
    [consts.size.wide[2]]: {
      [consts.size.long[0]]: 34.0,
      [consts.size.long[1]]: 34.0,
      [consts.size.long[2]]: 34.0
    },
    [consts.size.wide[3]]: {
      [consts.size.long[0]]: 0,
      [consts.size.long[1]]: 0,
      [consts.size.long[2]]: 0
    }
  },
  [consts.moduleTypes[1]]: {
    [consts.size.wide[0]]: {
      [consts.size.long[0]]: 89.5,
      [consts.size.long[1]]: 89.5,
      [consts.size.long[2]]: 89.5
    },
    [consts.size.wide[1]]: {
      [consts.size.long[0]]: 49.0,
      [consts.size.long[1]]: 49.0,
      [consts.size.long[2]]: 49.0
    },
    [consts.size.wide[2]]: {
      [consts.size.long[0]]: 7.3,
      [consts.size.long[1]]: 7.3,
      [consts.size.long[2]]: 7.3
    },
    [consts.size.wide[3]]: {
      [consts.size.long[0]]: 0,
      [consts.size.long[1]]: 0,
      [consts.size.long[2]]: 0
    }
  }
};

const TRANSLATE_Z = {
  [consts.size.long[0]]: -40.0,
  [consts.size.long[1]]: -20.0,
  [consts.size.long[2]]: 0
};
