import styled from "styled-components";
import { Button } from "grommet";

export const ManualButton = styled(Button)`
  font-size: 16px;
  background-color: red;
  border: none;
  border-radius: 10px;
`;

ManualButton.defaultProps = {
  pad: "xxsmall",
  margin: "small"
};
