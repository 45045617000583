import { Box } from "grommet";
import styled from "styled-components";

export const DimensionsRow = styled(Box)`
  width: fit-content;
`;
DimensionsRow.defaultProps = {
  direction: "row"
};

export const Wrapper = styled(Box)``;

Wrapper.defaultProps = {
  direction: "column",
  justify: "center",
  align: "center",
  border: true,
  pad: "xsmall"
};
