export default [
  {
    name: "Truss",
    content: {
      rendered:
        "<p>The canopy’s front and rear trusses are the main components determining its lateral span and module capacity. Trusses are manufactured in two standard depths: 13” or 24”. The deeper 24” truss is engineered for canopies that have spans greater than 20’, such as double carports.</p>",
      raw:
        "The canopy’s front and rear trusses are the main components determining its lateral span and module capacity. Trusses are manufactured in two standard depths: 13” or 24”. The deeper 24” truss is engineered for canopies that have spans greater than 20’, such as double carports."
    },
    position: [159.1195975737, -1.0684466362, 115.7996005642],
    eye: [195.0324221563, -153.2208541476, 33.6743440575],
    target: [174.7606721192, 119.7863395169, 95.8207007122],
    lastComputedScenePosition: [159.1195975737, -1.0684466362, 115.7996005642],
    lastComputedWorldPosition: [159.1195975737, -1.0684466362, 115.7996005642],
    uidImage: "4708f8b445d841868ede3a4d35e68525",
    imageFile: {},
    preview:
      "https://media.sketchfab.com/models/784b643a15ef428fa3c3aae4dac8a59c/b520f45cb3b1488eac5412dc59ef5d9e/hotspots/2a7a8a88b41a43d9aaa55a086caa5b4d/4708f8b445d841868ede3a4d35e68525.jpeg",
    hovered: true,
    geometryUid: 1353,
    indices: [2176, 2177, 2178],
    barycentricWeights: [0.5949381103, 0.1987003384, 0.2063615513],
    localPosition: [159.1195975737, 115.7996005642, 1.0684466362],
    visible: true,
    uid: "2a7a8a88b41a43d9aaa55a086caa5b4d",
    createdAt: "2019-09-23T15:16:49.980401",
    images: [
      {
        height: 128,
        updatedAt: "2019-10-20T14:40:17.758585",
        uid: "4708f8b445d841868ede3a4d35e68525",
        size: 8307,
        createdAt: "2019-10-15T19:53:35.976866",
        url:
          "https://media.sketchfab.com/models/784b643a15ef428fa3c3aae4dac8a59c/b520f45cb3b1488eac5412dc59ef5d9e/hotspots/2a7a8a88b41a43d9aaa55a086caa5b4d/4708f8b445d841868ede3a4d35e68525.jpeg",
        width: 128
      }
    ],
    updatedAt: "2019-10-29T14:31:59.487767"
  },
  {
    name: "Rail",
    content: {
      rendered:
        "<p>Solar modules are fastened to the canopy&rsquo;s rails which perpendicularly span between the canopy&rsquo;s two trusses. The number of rails per canopy is a function of truss width and the number of solar modules.</p>",
      raw:
        "Solar modules are fastened to the canopy's rails which perpendicularly span between the canopy's two trusses. The number of rails per canopy is a function of truss width and the number of solar modules."
    },
    position: [147.7334905232, -21.5269389468, 133.2510070801],
    eye: [144.8132094981, -179.8073368673, 13.3038084329],
    target: [159.7723256564, 71.7577864623, 124.9432351119],
    lastComputedScenePosition: [
      147.73349052318812,
      -21.526938946846485,
      133.25100708007812
    ],
    lastComputedWorldPosition: [
      147.73349052318812,
      -21.526938946846485,
      133.25100708007812
    ],
    uidImage: "59d4b0864eb140d9aa72b1164c155107",
    imageFile: {},
    preview:
      "https://media.sketchfab.com/models/784b643a15ef428fa3c3aae4dac8a59c/b520f45cb3b1488eac5412dc59ef5d9e/hotspots/a439762f2bee464797fc7245ce0e1594/59d4b0864eb140d9aa72b1164c155107.jpeg",
    hovered: true,
    geometryUid: 4469,
    indices: [296, 298, 297],
    barycentricWeights: [
      0.5476677076744746,
      0.4144450705721998,
      0.037887221753325595
    ],
    localPosition: [147.73349052318812, 133.25100708007812, 21.526938946846485],
    visible: true,
    uid: "a439762f2bee464797fc7245ce0e1594",
    createdAt: "2019-10-10T16:19:40.062952",
    images: [
      {
        height: 128,
        updatedAt: "2019-10-20T14:40:17.601177",
        uid: "59d4b0864eb140d9aa72b1164c155107",
        size: 8183,
        createdAt: "2019-10-10T16:19:40.071913",
        url:
          "https://media.sketchfab.com/models/784b643a15ef428fa3c3aae4dac8a59c/b520f45cb3b1488eac5412dc59ef5d9e/hotspots/a439762f2bee464797fc7245ce0e1594/59d4b0864eb140d9aa72b1164c155107.jpeg",
        width: 128
      }
    ],
    updatedAt: "2019-10-29T14:34:28.711383"
  },
  {
    name: "Leg Assembly",
    content: {
      rendered:
        "<p>The canopy&rsquo;s four leg assemblies attach to and elevate the trusses, and are integrated with the ground or roof attachment points. The signature A-Frame design distributes the point loading over a wide area, while also creating space for inverters, EV charge points, battery storage units, and other equipment.</p>",
      raw:
        "The canopy's four leg assemblies attach to and elevate the trusses, and are integrated with the ground or roof attachment points. The signature A-Frame design distributes the point loading over a wide area, while also creating space for inverters, EV charge points, battery storage units, and other equipment."
    },
    position: [310.4662997635, 210.3676709167, 90.8434857877],
    eye: [180.156847333, 91.9356931601, 92.0514389623],
    target: [260.477494128, 141.0524359483, 78.5432183178],
    lastComputedScenePosition: [
      310.46629976345923,
      210.36767091668727,
      90.8434857877007
    ],
    lastComputedWorldPosition: [
      310.46629976345923,
      210.36767091668727,
      90.8434857877007
    ],
    uidImage: "ee70b36621024b068df90eee0e47f2d4",
    imageFile: {},
    preview:
      "https://media.sketchfab.com/models/784b643a15ef428fa3c3aae4dac8a59c/b520f45cb3b1488eac5412dc59ef5d9e/hotspots/98d7fc1d76cb408f81dd77796f827dad/ee70b36621024b068df90eee0e47f2d4.jpeg",
    hovered: true,
    geometryUid: 1596,
    indices: [225, 226, 227],
    barycentricWeights: [
      0.16721353108576598,
      0.8092451829414646,
      0.023541285972769384
    ],
    localPosition: [310.46629976345923, 90.8434857877007, -210.36767091668727],
    visible: true,
    uid: "98d7fc1d76cb408f81dd77796f827dad",
    createdAt: "2019-10-10T16:28:11.388865",
    images: [
      {
        height: 128,
        updatedAt: "2019-10-20T14:40:17.446521",
        uid: "ee70b36621024b068df90eee0e47f2d4",
        size: 7615,
        createdAt: "2019-10-10T16:28:11.396878",
        url:
          "https://media.sketchfab.com/models/784b643a15ef428fa3c3aae4dac8a59c/b520f45cb3b1488eac5412dc59ef5d9e/hotspots/98d7fc1d76cb408f81dd77796f827dad/ee70b36621024b068df90eee0e47f2d4.jpeg",
        width: 128
      }
    ],
    updatedAt: "2019-10-29T16:19:11.189749"
  },
  {
    name: "Attachment plate/track",
    content: {
      rendered:
        "<p>Canopies can be anchored to surfaces with attachment plates  that are custom designed for different attachment options: concrete pads and beams or earth anchors for ground applications; and rafter and masonry walls for roof applications.</p>",
      raw:
        "Canopies can be anchored to surfaces with attachment plates  that are custom designed for different attachment options: concrete pads and beams or earth anchors for ground applications; and rafter and masonry walls for roof applications."
    },
    position: [-0.5443051457, 43.148935417, 3.5050682596],
    eye: [-16.4977670999, 33.4279706091, 22.9766431898],
    target: [104.7082465517, 91.7318550011, -111.7430198526],
    lastComputedScenePosition: [
      -0.544305145740509,
      43.148935416950955,
      3.5050682595617317
    ],
    lastComputedWorldPosition: [
      -0.544305145740509,
      43.148935416950955,
      3.5050682595617317
    ],
    uidImage: "b91d7a2ddf7b49b9a73b7f073dc3241d",
    imageFile: {},
    preview:
      "https://media.sketchfab.com/models/784b643a15ef428fa3c3aae4dac8a59c/b520f45cb3b1488eac5412dc59ef5d9e/hotspots/2aa348f255e24b459c1735b20515dc5e/b91d7a2ddf7b49b9a73b7f073dc3241d.jpeg",
    hovered: true,
    geometryUid: 1111,
    indices: [544, 548, 547],
    barycentricWeights: [
      0.12476588503554153,
      0.49228822438434744,
      0.3829458905801111
    ],
    localPosition: [
      -0.544305145740509,
      3.5050682595617317,
      -43.148935416950955
    ],
    visible: true,
    uid: "2aa348f255e24b459c1735b20515dc5e",
    createdAt: "2019-10-17T15:09:07.125141",
    images: [
      {
        height: 128,
        updatedAt: "2019-10-20T14:40:16.953673",
        uid: "b91d7a2ddf7b49b9a73b7f073dc3241d",
        size: 4407,
        createdAt: "2019-10-17T15:09:07.802326",
        url:
          "https://media.sketchfab.com/models/784b643a15ef428fa3c3aae4dac8a59c/b520f45cb3b1488eac5412dc59ef5d9e/hotspots/2aa348f255e24b459c1735b20515dc5e/b91d7a2ddf7b49b9a73b7f073dc3241d.jpeg",
        width: 128
      }
    ],
    updatedAt: "2019-10-29T14:40:00.069830"
  },
  {
    name: "Snow Brace",
    content: {
      rendered:
        "<p>In project areas where snow loads exceed 30 psf, additional bracing is required. The snow brace&rsquo;s two aluminum members attach to the front and back trusses beneath the rails, adding structural support.</p>",
      raw:
        "In project areas where snow loads exceed 30 psf, additional bracing is required. The snow brace's two aluminum members attach to the front and back trusses beneath the rails, adding structural support."
    },
    position: [190.6471633442, 103.8439767694, 129.9669952393],
    eye: [453.4215521939, 104.6447381718, 63.859799176],
    target: [181.5056115409, 122.3033786656, 131.3527012766],
    lastComputedScenePosition: [
      190.64716334419424,
      103.84397676938949,
      129.9669952392578
    ],
    lastComputedWorldPosition: [
      190.64716334419424,
      103.84397676938949,
      129.9669952392578
    ],
    uidImage: "9151a55e200044818f2eb095cdb67ff9",
    imageFile: {},
    preview:
      "https://media.sketchfab.com/models/784b643a15ef428fa3c3aae4dac8a59c/b520f45cb3b1488eac5412dc59ef5d9e/hotspots/917fc9f76b1e420e87c6ec4391119ac1/9151a55e200044818f2eb095cdb67ff9.jpeg",
    hovered: true,
    geometryUid: 5907,
    indices: [169, 170, 160],
    barycentricWeights: [
      0.07505177543717506,
      0.8435807698367354,
      0.08136745472608958
    ],
    localPosition: [190.64716334419424, 129.9669952392578, -103.84397676938949],
    visible: true,
    uid: "917fc9f76b1e420e87c6ec4391119ac1",
    createdAt: "2019-10-16T16:16:47.735544",
    images: [
      {
        height: 128,
        updatedAt: "2019-10-20T14:40:16.807303",
        uid: "9151a55e200044818f2eb095cdb67ff9",
        size: 7021,
        createdAt: "2019-10-16T16:16:48.353580",
        url:
          "https://media.sketchfab.com/models/784b643a15ef428fa3c3aae4dac8a59c/b520f45cb3b1488eac5412dc59ef5d9e/hotspots/917fc9f76b1e420e87c6ec4391119ac1/9151a55e200044818f2eb095cdb67ff9.jpeg",
        width: 128
      }
    ],
    updatedAt: "2019-10-20T14:40:16.811888"
  },
  {
    name: "front",
    content: {
      rendered: "",
      raw: ""
    },
    position: [166.1946898866, -1729.0469225537, 243.897243106],
    eye: [160.1672956918, -437.8483490282, 100.2642066169],
    target: [170.5738260609, 91.9756606086, 77.9375037281],
    lastComputedScenePosition: [
      166.1946898866,
      -1729.0469225537,
      243.897243106
    ],
    lastComputedWorldPosition: [
      166.1946898866,
      -1729.0469225537,
      243.897243106
    ],
    uidImage: "a623afc567ef4c10b1ef4b67365da3f7",
    imageFile: {},
    preview:
      "https://media.sketchfab.com/models/784b643a15ef428fa3c3aae4dac8a59c/b520f45cb3b1488eac5412dc59ef5d9e/hotspots/16798b80a56b4a6b8993c37306f7cdca/a623afc567ef4c10b1ef4b67365da3f7.jpeg",
    hovered: true,
    geometryUid: 6120,
    indices: [263, 264, 236],
    barycentricWeights: [0.2126040589, 0.4287894014, 0.3586065397],
    localPosition: [160.6508304414, 216.9544622516, 1232.5293710792],
    visible: true,
    uid: "16798b80a56b4a6b8993c37306f7cdca",
    createdAt: "2019-09-18T09:44:36.351047",
    images: [
      {
        height: 128,
        updatedAt: "2019-10-20T14:40:18.108227",
        uid: "a623afc567ef4c10b1ef4b67365da3f7",
        size: 5757,
        createdAt: "2019-09-20T17:26:49.072701",
        url:
          "https://media.sketchfab.com/models/784b643a15ef428fa3c3aae4dac8a59c/b520f45cb3b1488eac5412dc59ef5d9e/hotspots/16798b80a56b4a6b8993c37306f7cdca/a623afc567ef4c10b1ef4b67365da3f7.jpeg",
        width: 128
      }
    ],
    updatedAt: "2019-10-20T14:40:18.112943"
  },
  {
    name: "side",
    content: {
      rendered: "",
      raw: ""
    },
    position: [242.8074555025, 120.0923667381, 130.1266839973],
    eye: [-336.9851805474, 128.5999717292, 72.724279975],
    target: [161.0083153594, 125.0905855203, 43.9348711485],
    lastComputedScenePosition: [242.8074555025, 120.0923667381, 130.1266839973],
    lastComputedWorldPosition: [242.8074555025, 120.0923667381, 130.1266839973],
    uidImage: "2ac757060519489e911253f9fc36c460",
    imageFile: {},
    preview:
      "https://media.sketchfab.com/models/784b643a15ef428fa3c3aae4dac8a59c/b520f45cb3b1488eac5412dc59ef5d9e/hotspots/eb2340eb8afb43a1a0e23484d540a35c/2ac757060519489e911253f9fc36c460.jpeg",
    hovered: true,
    geometryUid: 4481,
    indices: [32, 33, 34],
    barycentricWeights: [0.3617513054, 0.5262348268, 0.1120138678],
    localPosition: [242.8074555025, 130.1266839973, -120.0923667381],
    visible: true,
    uid: "eb2340eb8afb43a1a0e23484d540a35c",
    createdAt: "2019-09-18T09:44:36.386546",
    images: [
      {
        height: 128,
        updatedAt: "2019-10-20T14:40:17.997587",
        uid: "2ac757060519489e911253f9fc36c460",
        size: 6789,
        createdAt: "2019-09-20T17:26:48.912747",
        url:
          "https://media.sketchfab.com/models/784b643a15ef428fa3c3aae4dac8a59c/b520f45cb3b1488eac5412dc59ef5d9e/hotspots/eb2340eb8afb43a1a0e23484d540a35c/2ac757060519489e911253f9fc36c460.jpeg",
        width: 128
      }
    ],
    updatedAt: "2019-10-20T14:40:18.000285"
  },
  {
    name: "angle",
    content: {
      rendered: "",
      raw: ""
    },
    position: [85.6551559874, 48.9693971422, 138.1724700928],
    eye: [-216.4295952651, -280.4974101991, 293.0560406585],
    target: [161.0083153594, 125.0905855203, 43.9348711485],
    lastComputedScenePosition: [85.6551559874, 48.9693971422, 138.1724700928],
    lastComputedWorldPosition: [85.6551559874, 48.9693971422, 138.1724700928],
    uidImage: "5d247ff7506946af985fe3f8e4c34f85",
    imageFile: {},
    preview:
      "https://media.sketchfab.com/models/784b643a15ef428fa3c3aae4dac8a59c/b520f45cb3b1488eac5412dc59ef5d9e/hotspots/f3f4978ef807481e8a42b9a9b49325d4/5d247ff7506946af985fe3f8e4c34f85.jpeg",
    hovered: true,
    geometryUid: 3575,
    indices: [17, 19, 18],
    barycentricWeights: [0.0538993795, 0.2452908052, 0.7008098153],
    localPosition: [85.6551559874, 138.1724700928, -48.9693971422],
    visible: true,
    uid: "f3f4978ef807481e8a42b9a9b49325d4",
    createdAt: "2019-09-20T17:26:48.937781",
    images: [
      {
        height: 128,
        updatedAt: "2019-10-20T14:40:17.879485",
        uid: "5d247ff7506946af985fe3f8e4c34f85",
        size: 7627,
        createdAt: "2019-09-20T17:26:48.955416",
        url:
          "https://media.sketchfab.com/models/784b643a15ef428fa3c3aae4dac8a59c/b520f45cb3b1488eac5412dc59ef5d9e/hotspots/f3f4978ef807481e8a42b9a9b49325d4/5d247ff7506946af985fe3f8e4c34f85.jpeg",
        width: 128
      }
    ],
    updatedAt: "2019-10-20T14:40:17.882807"
  }
];
