import styled from "styled-components";
import { Box, Image } from "grommet";
import { iconSizes } from "../consts";

const { size, sizeGap, space } = iconSizes;

export const HomeImage = styled(Image)`
  width: 18px;
  height: 18px;
  margin-top: 2px;
`;

export const CircleIconInner = styled(Box)`
  width: ${size}px;
  height: ${size}px;
  background-color: black;
  color: ${({ isHovered }) => (isHovered ? "white" : "gray")};
`;
CircleIconInner.defaultProps = {
  round: "full",
  align: "center"
};

export const CircleIconOuter = styled(Box)`
  margin: 0 ${space}px;
  cursor: pointer;
  width: ${size + sizeGap}px;
  height: ${size + sizeGap}px;
`;

CircleIconOuter.defaultProps = {
  round: "full",
  display: "flex",
  align: "center",
  justify: "center"
};
