import React from "react";
import { Table, TableRow, TableCell } from "grommet";
import { useGlobalState } from "state";
import {
  selectHeight,
  selectModuleType,
  selectWide,
  selectLong
} from "state/selectors";
import consts from "state/consts";

export default () => {
  const [state] = useGlobalState();
  const height = selectHeight(state);
  const moduleType = selectModuleType(state);
  const wide = selectWide(state);
  const long = selectLong(state);

  return (
    <Table>
      <tbody>
        <TableRow>
          <TableCell scope="col">Height</TableCell>
          <TableCell scope="col">{height}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="col">Clearance</TableCell>
          <TableCell scope="col">{consts.clearance[height]}’</TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="col">Ground Width</TableCell>
          <TableCell scope="col">
            {consts.groundWidth[moduleType][wide]}’
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="col">Ground Length</TableCell>
          <TableCell scope="col">{consts.groundLength[long]}’</TableCell>
        </TableRow>
      </tbody>
    </Table>
  );
};
