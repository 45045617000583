import consts from "state/consts";
import flat from "./flat.svg";
import front from "./front.svg";
import lateral from "./lateral.svg";

const dimensions = {
  [consts.orientation.flat]: flat,
  [consts.orientation.front]: front,
  [consts.orientation.lateral]: lateral
};

export default dimensions;
