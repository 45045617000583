import React from "react";
import { useGlobalState, changeAttachments, consts } from "state";
import { selectAttachments, selectPreset } from "state/selectors";
import { Box } from "grommet";
import RadioButtonGroup from "components/RadioButtonGroup";
import { groundOptions, rooftopOptions } from "./options";

export default () => {
  const [state, dispatch] = useGlobalState();

  const attachments = selectAttachments(state);

  const preset = selectPreset(state);

  const options =
    preset === consts.presets.rooftop ? rooftopOptions : groundOptions;

  const onAttachmentsChange = event => {
    dispatch(changeAttachments(event.target.value));
  };

  return (
    <Box>
      <RadioButtonGroup
        name="attachments"
        options={options}
        value={attachments}
        onChange={onAttachmentsChange}
      />
    </Box>
  );
};
