import { useContext, useReducer, useEffect } from "react";
import StateContext from "./context";
import {
  buildInitialStateFromSearch,
  buildUrlParamsString,
  formatStateToParams
} from "./utils";
import { stateReducer, initialState } from "./reducer";

const defaultSelector = state => state;

export const useGlobalState = (selector = defaultSelector) => {
  const { state, dispatch } = useContext(StateContext);

  return [selector(state), dispatch];
};

export const useGlobalReducer = (search = "") => {
  const initial = initialState;
  initial.model = {
    ...initialState.model,
    ...buildInitialStateFromSearch(search)
  };

  const [state, dispatch] = useReducer(stateReducer, initial);

  return [state, dispatch];
};

export const useHistoryPush = state => {
  useEffect(() => {
    const pageUrl =
      "?" + buildUrlParamsString(formatStateToParams(state.model));
    window.history.pushState("", "", pageUrl);
  }, [state]);
};

export const useVendorUpdate = state => {
  useEffect(() => {
    window.onCanopyUpdate =
      window.onCanopyUpdate ||
      function name(data) {
        // eslint-disable-next-line no-console
        console.warn("from onCanopyUpdate");
        // eslint-disable-next-line no-console
        console.warn(data);
      };

    const stateParamsObject = formatStateToParams(state.model);

    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      buildUrlParamsString(stateParamsObject);

    window.onCanopyUpdate({
      params: stateParamsObject,
      newUrl: newurl
    });
  }, [state]);
};
