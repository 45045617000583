import React from "react";
import range from "lodash/range";
import SizeBlock from "./DimensionsBlock";
import { Box, Text } from "grommet";
import { DimensionsRow, Wrapper } from "./DimensionInput.style";

const COLUMN_OFFSET = 2;
const ROW_OFFSET = 5;

export default ({
  rows = 3,
  columns = 4,
  wide,
  long,
  onWideChange,
  onLongChange,
  changeModuleType,
  isActive: isMooduleActive,
  label,
  disabledSizes = []
}) => (
  <Wrapper>
    <Text margin={{ bottom: "small" }}>{label}</Text>
    {range(0, rows).map(rowIndex => (
      <DimensionsRow direction="row" key={rowIndex}>
        {range(0, columns).map(columnIndex => {
          let isActive = false;

          const currentWide = columnIndex + COLUMN_OFFSET;
          const currentLong = rowIndex + ROW_OFFSET;

          if (wide === currentWide && long === currentLong) {
            isActive = true;
          }

          const isDisabled = disabledSizes.includes(
            `${currentWide}x${currentLong}`
          );

          return (
            <SizeBlock
              isDisabled={isDisabled}
              key={columnIndex}
              onChange={() => {
                changeModuleType();
                onWideChange(currentWide.toString());
                onLongChange(currentLong.toString());
              }}
              isActive={isActive && isMooduleActive}
              margin="xsmall"
            >
              {currentWide}/{currentLong}
            </SizeBlock>
          );
        })}
      </DimensionsRow>
    ))}
  </Wrapper>
);
