import React from "react";
import { CircleIconInner, CircleIconOuter } from "./Icon.style";

export default function({
  isHovered,
  content,
  name,
  onHover,
  onLeave,
  onIconClick
}) {
  const handleHover = () => onHover(name);
  const handleOut = ({ target, relatedTarget }) => {
    if (
      (target && target.id === "innerCircle") ||
      (relatedTarget && relatedTarget.id === "innerCircle")
    ) {
      return;
    }
    onLeave();
  };
  const handleClick = () => onIconClick(name);

  return (
    <CircleIconOuter
      background={isHovered ? "light-2" : "dark-3"}
      onMouseOver={handleHover}
      onMouseOut={handleOut}
      onClick={handleClick}
    >
      <CircleIconInner id="innerCircle" isHovered={isHovered}>
        {content}
      </CircleIconInner>
    </CircleIconOuter>
  );
}
