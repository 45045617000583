import { consts } from "state";
import { transformNode } from "./helpers";
import {
  SCALE_Z,
  TRANSLATE_Z_03,
  TRANSLATE_Z_01,
  TRANSLATE_Z_02
} from "./consts/long";

export default async (sketchfab, { long }) => {
  ["beam_01", "beam_02"].forEach(nodeName =>
    transformNode(sketchfab, nodeName, {
      translate: {
        z: TRANSLATE_Z_03[long]
      },
      scale: {
        z: SCALE_Z[long]
      }
    })
  );

  ["beam_01_support_02", "beam_02_support_02"].forEach(nodeName =>
    transformNode(sketchfab, nodeName, {
      translate: {
        z: TRANSLATE_Z_01[long]
      }
    })
  );

  ["beam_01_support_01", "beam_02_support_01"].forEach(nodeName =>
    transformNode(sketchfab, nodeName, {
      translate: {
        z: TRANSLATE_Z_02[long]
      }
    })
  );
};
