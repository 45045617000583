import consts from "./consts";
import { parseSizesString } from "./utils";

export const CHANGE_MODULE_TYPE = "CHANGE_MODULE_TYPE";
export const CHANGE_ATTACHMENTS = "CHANGE_ATTACHMENTS";
export const CHANGE_WIDE = "CHANGE_WIDE";
export const CHANGE_LONG = "CHANGE_LONG";
export const CHANGE_HEIGHT = "CHANGE_HEIGHT";
export const CHANGE_ORIENTATION = "CHANGE_ORIENTATION";
export const CHANGE_LEGS_COLOR = "CHANGE_LEGS_COLOR";
export const CHANGE_TRUSS_COLOR = "CHANGE_TRUSS_COLOR";
export const CHANGE_SETTING = "CHANGE_SETTING";
export const CHANGE_UTILIZATION = "CHANGE_UTILIZATION";
export const SET_MODEL_STATE = "SET_MODEL_STATE";
export const TOGGLE_SNOW_BRACE = "TOGGLE_SNOW_BRACE";
export const SET_DISABLED_SIZES = "SET_DISABLED_SIZES";
export const CHANGE_ACTIVE_SIDEBAR_MODAL = "CHANGE_ACTIVE_SIDEBAR_MODAL";
export const CHANGE_MEASUREMENTS = "CHANGE_MEASUREMENTS";

// Manual
export const CHANGE_MANUAL_SURFACE = "CHANGE_MANUAL_SURFACE";
export const SET_ACTIVE_MENU_ITEM = "SET_ACTIVE_MENU_ITEM";
export const SET_MANUAL_SUBSTEP = "SET_MANUAL_SUBSTEP";

export const initialState = {
  model: {
    moduleType: consts.moduleTypes[0],
    attachments: consts.attachments.beams,
    wide: consts.size.defaultWide,
    long: consts.size.defaultLong,
    height: consts.size.defaultHeight,
    orientation: consts.orientation.default,
    legsColor: consts.colors[0].value,
    trussColor: consts.colors[0].value,
    setting: consts.environment.defaultSetting[consts.presets.ground],
    utilization: "none",
    snowBrace: false,
    disabledSizes: {
      [consts.moduleTypes[0]]: [],
      [consts.moduleTypes[1]]: []
    },
    preset: consts.presets.ground
  },
  activeSidebarModal: null,
  activeMenuItem: null,
  manual: {
    surface: "rooftop",
    subStep: null
  },
  measurements: consts.measurements
};

const defaultAction = {
  type: "",
  payload: ""
};

export function stateReducer(state = initialState, action = defaultAction) {
  switch (action.type) {
    case CHANGE_MODULE_TYPE: {
      const newState = { ...state };
      newState.model.moduleType = action.payload;

      if (
        newState.model.moduleType === consts.moduleTypes[1] &&
        newState.model.wide === consts.size.wide[3]
      ) {
        newState.model.wide = consts.size.wide[2];
      }

      if (
        newState.model.moduleType === consts.moduleTypes[0] &&
        newState.model.wide === consts.size.wide[2]
      ) {
        newState.model.wide = consts.size.wide[3];
      }

      return newState;
    }

    case CHANGE_ATTACHMENTS: {
      const newState = { ...state };
      newState.model.attachments = action.payload;
      return newState;
    }

    case CHANGE_LONG: {
      const newState = { ...state };
      newState.model.long = action.payload;
      return newState;
    }

    case CHANGE_WIDE: {
      const newState = { ...state };
      newState.model.wide = action.payload;
      return newState;
    }

    case CHANGE_ORIENTATION: {
      const newState = { ...state };
      newState.model.orientation = action.payload;
      return newState;
    }

    case CHANGE_LEGS_COLOR: {
      const newState = { ...state };
      newState.model.legsColor = action.payload;
      return newState;
    }

    case CHANGE_TRUSS_COLOR: {
      const newState = { ...state };
      newState.model.trussColor = action.payload;
      return newState;
    }

    case CHANGE_HEIGHT: {
      const newState = { ...state };
      newState.model.height = action.payload;
      return newState;
    }

    case CHANGE_SETTING: {
      const newState = { ...state };
      newState.model.setting = action.payload;
      return newState;
    }

    case CHANGE_UTILIZATION: {
      const newState = { ...state };
      newState.model.utilization = action.payload;
      return newState;
    }

    case TOGGLE_SNOW_BRACE: {
      const newState = { ...state };
      newState.model.snowBrace = !newState.model.snowBrace;
      return newState;
    }

    case SET_MODEL_STATE: {
      const newState = { ...state };
      newState.model = {
        ...newState.model,
        ...action.payload
      };

      return newState;
    }

    case SET_DISABLED_SIZES: {
      const newState = { ...state };
      const { moduleType, sizesString } = action.payload;

      newState.model.disabledSizes[moduleType] = parseSizesString(sizesString);

      return newState;
    }

    case CHANGE_MANUAL_SURFACE: {
      return {
        ...state,
        manual: {
          ...state.manual,
          surface: action.payload
        }
      };
    }

    case SET_ACTIVE_MENU_ITEM: {
      return {
        ...state,
        activeMenuItem: action.payload
      };
    }

    case SET_MANUAL_SUBSTEP: {
      return {
        ...state,
        manual: {
          ...state.manual,
          subStep: action.payload
        }
      };
    }

    case CHANGE_ACTIVE_SIDEBAR_MODAL: {
      return {
        ...state,
        activeSidebarModal: action.payload
      };
    }

    case CHANGE_MEASUREMENTS: {
      return {
        ...state,
        measurements: action.payload
      };
    }

    default:
      return state;
  }
}

export const changeMeasurements = payload => ({
  type: CHANGE_MEASUREMENTS,
  payload
});

export const changeActiveSidebarModal = payload => ({
  type: CHANGE_ACTIVE_SIDEBAR_MODAL,
  payload
});

export const hideSidebarModal = () => changeActiveSidebarModal(null);

export const setManualSubstep = payload => ({
  type: SET_MANUAL_SUBSTEP,
  payload
});

export const setActiveMenuItem = payload => ({
  type: SET_ACTIVE_MENU_ITEM,
  payload
});

export const changeManualSurface = payload => ({
  type: CHANGE_MANUAL_SURFACE,
  payload
});

export const changeModuleType = (payload = consts.moduleTypes[0]) => ({
  type: CHANGE_MODULE_TYPE,
  payload
});

export const changeAttachments = payload => ({
  type: CHANGE_ATTACHMENTS,
  payload
});

export const changeWide = payload => ({
  type: CHANGE_WIDE,
  payload
});

export const changeLong = payload => ({
  type: CHANGE_LONG,
  payload
});

export const changeOrientation = payload => ({
  type: CHANGE_ORIENTATION,
  payload
});

export const changeLegsColor = payload => ({
  type: CHANGE_LEGS_COLOR,
  payload
});

export const changeTrussColor = payload => ({
  type: CHANGE_TRUSS_COLOR,
  payload
});

export const changeHeight = payload => ({
  type: CHANGE_HEIGHT,
  payload
});

export const changeSetting = payload => ({
  type: CHANGE_SETTING,
  payload
});

export const changeUtilization = payload => ({
  type: CHANGE_UTILIZATION,
  payload
});

export const setModelState = (modelState = {}) => ({
  type: SET_MODEL_STATE,
  payload: modelState
});

export const setDisabledSizes = (moduleType, sizesString) => ({
  type: SET_DISABLED_SIZES,
  payload: {
    moduleType,
    sizesString
  }
});
