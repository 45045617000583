import React from "react";
import { Box, Text } from "grommet";
import styled from "styled-components";

const HeadingText = styled(Text)``;
HeadingText.defaultProps = {
  size: "large",
  margin: { bottom: "24px" }
};

const HeadingWrapper = styled(Box)`
  display: inline;
`;
HeadingWrapper.defaultProps = {
  border: "bottom"
};

export const Heading = ({ children }) => (
  <HeadingText>
    <HeadingWrapper border="bottom">{children}</HeadingWrapper>
  </HeadingText>
);
