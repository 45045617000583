import React, { useCallback } from "react";
import {
  ActiveRadioIcon,
  RadioIcon,
  RadioContainer
} from "./ManualSubstepsRadioGroup.style";
import { Text } from "grommet";

export default function({ checked, onChange, label }) {
  const handleclick = useCallback(() => onChange(label), [label, onChange]);

  return (
    <RadioContainer>
      {checked ? (
        <ActiveRadioIcon onClick={handleclick} />
      ) : (
        <RadioIcon onClick={handleclick} />
      )}
      <Text>{label}</Text>
    </RadioContainer>
  );
}
