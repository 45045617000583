import React from "react";
import { Container, CloseIcon } from "./Description.style";
import { annotations, ANNOTATIONS_DATA } from "../consts";

export default function({ name, onClose }) {
  const index = annotations.findIndex(aName => aName === name) + 1;
  if (index <= 0) {
    return null;
  }

  const data = ANNOTATIONS_DATA.find(a => a.name === name);

  const text = data.content.raw;

  return (
    <Container annIndex={index}>
      <CloseIcon onClick={onClose}>x</CloseIcon>
      {text}
    </Container>
  );
}
