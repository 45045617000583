import { useState, useCallback } from "react";
import { useGlobalState } from "state";
import { setActiveMenuItem } from "state/reducer";
import {
  MANUAL_MENU_LABELS,
  MAIN_MANUAL_MENU_LABEL
} from "components/Sidebar/consts";

export const useDropSide = label => {
  const [state, dispatch] = useGlobalState();
  const [isControlVisible, setIsControlVisible] = useState();

  const showControls = useCallback(() => {
    setIsControlVisible(true);
    dispatch(setActiveMenuItem(label));
  }, [label, dispatch]);

  const hideControls = useCallback(() => {
    setIsControlVisible(false);
    if (MANUAL_MENU_LABELS.includes(label)) {
      dispatch(setActiveMenuItem(MAIN_MANUAL_MENU_LABEL));
    } else {
      dispatch(setActiveMenuItem(null));
    }
  }, [label, dispatch]);

  return {
    isControlVisible,
    showControls,
    hideControls
  };
};
