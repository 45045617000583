import { consts } from "state";

export const TRANSLATE_Z_01 = {
  [consts.size.long[0]]: 88,
  [consts.size.long[1]]: 54,
  [consts.size.long[2]]: 23
};

export const TRANSLATE_Z_02 = {
  [consts.size.long[0]]: -9,
  [consts.size.long[1]]: -18,
  [consts.size.long[2]]: -23
};

export const TRANSLATE_Z_03 = {
  [consts.size.long[0]]: 9,
  [consts.size.long[1]]: 4.5,
  [consts.size.long[2]]: 0
};

export const SCALE_Z = {
  [consts.size.long[0]]: 0.72,
  [consts.size.long[1]]: 0.86,
  [consts.size.long[2]]: 1
};
