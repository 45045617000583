import { srgbToLinear, hexToRgb } from "./colorTools.helper";

export default async (sketchfab, hex, part = "legs") => {
  if (hex) {
    const material = part === "legs" ? "colored_metal" : "rails_colored_metal";
    const defaultMaterial = { ...sketchfab._getMaterialByName(material) };

    if (hex === "#5E5E5E") {
      defaultMaterial.channels.AlbedoPBR.color = null;
      defaultMaterial.channels.AlbedoPBR.texture = sketchfab._getMaterialByName(
        "Aluminum"
      ).channels.AlbedoPBR.texture;
    } else {
      const linearColor = srgbToLinear(hexToRgb(hex));
      defaultMaterial.channels.AlbedoPBR.texture = null;
      defaultMaterial.channels.AlbedoPBR.color = linearColor;
    }

    sketchfab.setMaterial(defaultMaterial);
  }
};
