import { base } from "grommet/themes/base";

// const baseTheme = base();

const dimensionsInput = {
  size: 28
};

const sider = {
  manual: {
    width: "600px"
  },
  width: "400px",
  background: "rgb(69,70,71)"
};

const logo = {
  height: "90px"
};

const colors = {
  ...base.global.colors,
  text: { dark: "#f8f8f8", light: "#444444" },
  control: "light-1",
  active: "light-1",
  focus: "light-1",
  background: "#252525",
  "accent-3": "rgb(82,179,203)",
  "status-disabled": "rgb(130,133,139)"
};

export default {
  logo,
  dimensionsInput,
  sider,
  footer: {
    background: "#1C1C1C"
  },
  global: {
    colors,
    hover: { color: "active", opacity: "medium" }
  },
  button: {
    border: {
      radius: "0px",
      width: "1px"
    }
  },
  tabs: {
    tabBackground: {
      active: colors["accent-3"],
      disabled: colors["status-disabled"]
    },
    header: {
      // extend: () => ({
      //   "border-bottom": `1px solid ${colors["accent-3"]}`
      // })
    }
  },
  box: {}
};
