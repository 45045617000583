const wide = ["2", "3", "4", "5"];
const long = ["5", "6", "7"];
const height = ["12", "13", "14", "15"];
const moduleTypes = ["60", "72"];

const clearance = {
  [height[0]]: 7,
  [height[1]]: 8,
  [height[2]]: 9,
  [height[3]]: 10
};
const orientation = {
  flat: "flat",
  front: "front",
  lateral: "lateral"
};

const setting = {
  empty: "empty",
  desert: "desert",
  city: "city"
};

const utilization = {
  none: "none",
  car: "car",
  hammock: "hammock",
  picnic: "picnic",
  dining: "dining"
};

const groundWidth = {
  [moduleTypes[0]]: {
    [wide[0]]: 12,
    [wide[1]]: 18,
    [wide[2]]: 24,
    [wide[3]]: 30
  },
  [moduleTypes[1]]: {
    [wide[0]]: 14,
    [wide[1]]: 22,
    [wide[2]]: 28,
    [wide[3]]: 28
  }
};

const groundLength = {
  [long[0]]: 18,
  [long[1]]: 20,
  [long[2]]: 24
};

const presets = {
  ground: "ground",
  rooftop: "rooftop"
};

export const measurements = {
  truss_depth: "t1",
  interior_truss_span: "t2",
  min_clearance: "t3",
  exterior_truss_span: "t4",
  max_clearance: "t5",
  min_rail_cantilever: "t6",
  max_rail_span: "t7",
  min_array_height: "t8",
  max_ground_length: "t9",
  max_array_height: "t10",
  array_width: "t11",
  array_length: "t12",
  max_leg_spread: "t13",
  max_interior_leg_separation: "t14",
  min_leg_spread: "t15",
  min_interior_leg_separation: "t16"
};

export default {
  measurements,
  presets,
  groundWidth,
  groundLength,
  moduleTypes,
  attachments: {
    pads: "pads",
    beams: "beams",
    anchors: "anchors",
    tTrack: "t-track"
  },
  clearance,
  size: {
    wide,
    long,
    height,
    defaultWide: wide[1],
    defaultLong: long[2],
    defaultHeight: height[1]
  },
  orientation: {
    ...orientation,
    default: orientation.flat
  },
  colors: [
    {
      name: "Mill Finish (Standard)",
      value: "#5E5E5E",
      selectColor: "#5E5E5E",
      rgb: "94,94,94",
      cmyk: "0,0,0,0.630"
    },
    {
      name: "Matte Black",
      value: "#0a0a0a",
      selectColor: "#0a0a0a",
      rgb: "10,10,10",
      cmyk: "0,0,0,0.960"
    },
    {
      name: "Matte White",
      value: "#CBCBCB",
      selectColor: "#F2F3F4",
      rgb: "242, 243, 244",
      cmyk: "0.008,0.004,0,0.043"
    },
    {
      name: "Yellow Gold",
      value: "#FFCD00",
      selectColor: "#FFCD00",
      rgb: "255,205,0",
      cmyk: "0,0.200,1,0"
    },
    {
      name: "Boulder",
      value: "#F75E25",
      selectColor: "#F75E25",
      rgb: "247,94,37",
      cmyk: "0,0.620,0.850,0.030"
    },
    {
      name: "Cobble Hill",
      value: "#9E9764",
      selectColor: "#9E9764",
      rgb: "158,151,100",
      cmyk: "0,0.040,0.370,0.380"
    },
    {
      name: "Gowanus Green",
      value: "#35682D",
      selectColor: "#35682D",
      rgb: "53,104,45",
      cmyk: "0.490,0,0.570,0.590"
    },
    {
      name: "BK Blue",
      value: "#1B5583",
      selectColor: "#1B5583",
      rgb: "27,85,131",
      cmyk: "0.790,0,350,0,0.490"
    },
    {
      name: "Red Hook",
      value: "#F80000",
      selectColor: "#F80000",
      rgb: "248,0,0",
      cmyk: "0,1,1,0.030"
    }
  ],
  environment: {
    defaultSetting: setting.desert,
    setting,
    utilization
  }
};
