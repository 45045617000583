import consts from "./consts";
import {
  MANUAL_MENU_LABELS,
  MAIN_MANUAL_MENU_LABEL
} from "components/Sidebar/consts";

export const selectActiveSidebarModal = state => state.activeSidebarModal;

export const selectManualSurface = state => state.manual.surface;

export const selectActiveMenuItem = state => state.activeMenuItem;

export const selectManualSubstep = state => state.manual.subStep;

export const selectIsManual = state =>
  [MAIN_MANUAL_MENU_LABEL, ...MANUAL_MENU_LABELS].includes(
    state.activeMenuItem
  );

export const selectIsAnnotationsVisible = state =>
  ![MAIN_MANUAL_MENU_LABEL, ...MANUAL_MENU_LABELS].includes(
    state.activeMenuItem
  );

export const selectIsOpenManualStep = state =>
  selectIsManual(state) &&
  selectActiveMenuItem(state) &&
  MANUAL_MENU_LABELS.includes(selectActiveMenuItem(state));

export const selectModuleType = state => {
  return state.model.moduleType;
};

export const selectMeasurements = state => state.measurements;

export const selectAttachments = state => state.model.attachments;

export const selectWide = state => state.model.wide;
export const selectLong = state => state.model.long;
export const selectOrientation = state => state.model.orientation;

export const selectLegsColor = state => state.model.legsColor;
export const selectTrussColor = state => state.model.trussColor;
export const selectHeight = state => state.model.height;

export const selectSize = state => ({
  wide: selectWide(state),
  height: selectHeight(state),
  long: selectLong(state)
});

export const selectSetting = state => state.model.setting;
export const selectUtilization = state => state.model.utilization;

export const selectEnvironment = state => ({
  setting: selectSetting(state),
  utilization: selectUtilization(state)
});

export const selectSnowBrace = state => state.model.snowBrace;

export const selectDisabledSizes = state => state.model.disabledSizes;

export const selectPreset = state => state.model.preset;
