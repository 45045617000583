import React from "react";
import { Box, Text, Heading } from "grommet";

export default ({ name = "", selectColor = "", rgb = "", cmyk = "" }) => (
  <Box margin={{ bottom: "medium" }}>
    <Box background={selectColor} pad="25%" margin={{ bottom: "small" }} />
    <Heading textAlign="center" level={3} margin="0px">
      {name}
    </Heading>
    <ul>
      <li>Hex: {selectColor}</li>
      <li>RGB: ({rgb})</li>
      <li>CMYK: {cmyk}</li>
    </ul>
  </Box>
);
