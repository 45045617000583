export const MAIN_MANUAL_MENU_LABEL = "Manual instructions";

export const MANUAL_MENU_LABELS = [
  "Site Assessment and Canopy Sizing",
  "Base Track Layout and Fastening",
  "Truss and Leg Assembly",
  "Truss and Leg Installation",
  "Rail Assembly, Installation and grounding",
  "Module installation"
];

export const MANUAL_SUBSTEPS = {
  [MANUAL_MENU_LABELS[0]]: [
    "Determine roof structure and rafter framing",
    "Survey roof dimensions",
    "Determine canopy width",
    "Determine attachment point locations",
    "Transcribe site survey data to BSCC Design Form"
  ],
  [MANUAL_MENU_LABELS[1]]: [
    "Mark canopy footprint, base track locations and attachment points",
    "Locate and mark rafters under base track",
    "Mark and drill bolt holes on base track",
    "Fasten base track to roof"
  ],
  [MANUAL_MENU_LABELS[2]]: [
    "Splice together both trusses",
    "Mark rail positions on trusses",
    "Slide truss clips into truss bolt channels",
    "Bolt feet into legs",
    "Fasten legs to trusses"
  ],
  [MANUAL_MENU_LABELS[3]]: [
    "Place inverted Truss 1 in pre-installation position",
    "Rotate Truss 1 to installation position",
    "Place the feet on base track, drill holes",
    "Bolt feet to base track flange",
    "Repeat steps 1-4 for Truss 2"
  ],
  [MANUAL_MENU_LABELS[4]]: [
    "Splice together rail sections",
    "Elevate and span rails between trusses",
    "Secure rails to trusses with  rail clamps",
    "Connect  ground lug in rail nearest to junction box"
  ],
  [MANUAL_MENU_LABELS[5]]: [
    "Place modules in rows on rails",
    "Fasten modules to rail with IronRidge UFO clamps"
  ]
};
