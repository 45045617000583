import React from "react";
import { Text, Box, Image } from "grommet";
import ColorBlock from "./ColorBlock";
import { consts } from "state";

const VisualImage = ({ src, text }) => (
  <div>
    <img style={{ width: "100%", height: "auto" }} src={src} />
  </div>
);

export default {
  Orientation: () => (
    <div>
      <VisualImage src={require("./illustrations/orientation.jpg")} />
    </div>
  ),
  Size: preset => {
    switch (preset) {
      case consts.presets.ground:
        return (
          <div>
            <VisualImage src={require("./illustrations/size_g_1.jpg")} />
            <VisualImage src={require("./illustrations/size_g_2.jpg")} />
            <VisualImage src={require("./illustrations/size_g_3.jpg")} />
            <VisualImage src={require("./illustrations/size_g_4.jpg")} />
            <VisualImage src={require("./illustrations/size_g_5.jpg")} />
            <VisualImage src={require("./illustrations/size_g_6.jpg")} />
          </div>
        );
      case consts.presets.rooftop:
        return (
          <div>
            <VisualImage src={require("./illustrations/size_r_1.jpg")} />
            <VisualImage src={require("./illustrations/size_r_2.jpg")} />
            <VisualImage src={require("./illustrations/size_r_3.jpg")} />
            <VisualImage src={require("./illustrations/size_r_4.jpg")} />
            <VisualImage src={require("./illustrations/size_r_5.jpg")} />
          </div>
        );
      default:
        return null;
    }
  },
  Attachments: preset => {
    switch (preset) {
      case consts.presets.ground:
        return (
          <div>
            <VisualImage src={require("./illustrations/attachments_g_1.jpg")} />
            <VisualImage src={require("./illustrations/attachments_g_2.jpg")} />
            <VisualImage src={require("./illustrations/attachments_g_3.jpg")} />
            <VisualImage src={require("./illustrations/attachments_g_4.jpg")} />
          </div>
        );
      case consts.presets.rooftop:
        return (
          <div>
            <VisualImage src={require("./illustrations/attachments_r_1.jpg")} />
            <VisualImage src={require("./illustrations/attachments_r_2.jpg")} />
          </div>
        );
      default:
        return null;
    }
  },
  Color: () => (
    <React.Fragment>
      {consts.colors.map(color => (
        <ColorBlock {...color} key={color.value} />
      ))}
    </React.Fragment>
  )
};
