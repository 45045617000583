import { consts } from "state";

export default async (sketchfab, { moduleType, wide, long }) => {
  const [eye, target] = ANNOTATION_POINTS[moduleType][wide][long];

  let annotatinIndex = sketchfab.annotations.findIndex(
    el => el.name === "Attachment plate/track"
  ); // DANGER!!! HARDCODE no other way ;-/
  if (annotatinIndex === -1) annotatinIndex = 3; // DANGER!!! HARDCODE no other way ;-/

  sketchfab.updateAnnotation(annotatinIndex, {
    eye,
    target
  });
};

const ANNOTATION_POINTS = {
  [consts.moduleTypes[0]]: {
    [consts.size.wide[0]]: {
      [consts.size.long[0]]: [
        [85.63971374884196, 90.79996513498708, 17.065374671952732],
        [148.29286806260743, 83.90260257148678, -51.59894816777436]
      ],
      [consts.size.long[1]]: [
        [85.41296937944273, 79.53113858423688, 17.06537467195276],
        [148.29286806260743, 83.90260257148678, -51.59894816777436]
      ],
      [consts.size.long[2]]: [
        [77.27516506107712, 67.0918554120896, 13.331709386041549],
        [151.7407138997742, 63.24397173283983, -31.91388209883389]
      ]
    },
    [consts.size.wide[1]]: {
      [consts.size.long[0]]: [
        [47.07377807937195, 91.96497115143998, 15.545212759234346],
        [146.9780816482177, 88.09324717283512, -52.35493980117052]
      ],
      [consts.size.long[1]]: [
        [47.33602650516114, 79.88831176719923, 15.545212759234388],
        [146.9780816482177, 88.09324717283512, -52.35493980117052]
      ],
      [consts.size.long[2]]: [
        [35.611068563996795, 60.87881146401968, 27.81493240392797],
        [149.72925257432286, 82.67360907418794, -70.24492309096806]
      ]
    },
    [consts.size.wide[2]]: {
      [consts.size.long[0]]: [
        [9.926858031338412, 90.71634132936448, 11.797888145380881],
        [146.9780816482177, 88.09324717283512, -52.35493980117052]
      ],
      [consts.size.long[1]]: [
        [10.292251506603009, 78.5164353440789, 11.916145183229418],
        [146.9780816482177, 88.09324717283512, -52.35493980117052]
      ],
      [consts.size.long[2]]: [
        [5.846780640696181, 56.82389765328464, 29.951646699783083],
        [127.38509226805131, 90.19219480127515, -90.17162305424456]
      ]
    },
    [consts.size.wide[3]]: {
      [consts.size.long[0]]: [
        [-27.92379301624416, 90.54354563318097, 25.356345161452467],
        [107.8182327725211, 95.71744428413429, -108.01142267724718]
      ],
      [consts.size.long[1]]: [
        [-26.537556723283018, 75.68775079054008, 25.356345161452438],
        [107.8182327725211, 95.71744428413429, -108.01142267724718]
      ],
      [consts.size.long[2]]: [
        [-22.37969170042892, 57.85316237012929, 25.963382750142657],
        [104.70824655169997, 91.7318550011, -111.74301985260003]
      ]
    }
  },
  [consts.moduleTypes[1]]: {
    [consts.size.wide[0]]: {
      [consts.size.long[0]]: [
        [61.0492415589037, 87.85194901495417, 29.890698263999866],
        [142.9545007073123, 96.02907187339535, -58.56945934177243]
      ],
      [consts.size.long[1]]: [
        [66.71031726126947, 83.47577738293644, 26.17706080635329],
        [131.0655403463918, 80.21555840863101, -45.2605006715604]
      ],
      [consts.size.long[2]]: [
        [73.59980436597934, 67.51719440925102, 29.621160969984686],
        [131.81459972383956, 68.4745144560135, -100.07814593978432]
      ]
    },
    [consts.size.wide[1]]: {
      [consts.size.long[0]]: [
        [15.782707484543678, 89.63722274297714, 21.861214492196012],
        [142.9545007073123, 96.02907187339535, -58.56945934177243]
      ],
      [consts.size.long[1]]: [
        [31.05364001721597, 76.05865405750833, 27.658586548523886],
        [104.70824655169996, 91.73185500110003, -111.74301985260001]
      ],
      [consts.size.long[2]]: [
        [19.459571821813228, 48.674821429557966, 52.93358324444908],
        [104.70824655170001, 91.7318550011, -111.74301985259997]
      ]
    },
    [consts.size.wide[2]]: {
      [consts.size.long[0]]: [
        [-20.416050605436553, 89.21847893107291, 31.704606452015682],
        [104.7082465517, 91.7318550011, -111.7430198526]
      ],
      [consts.size.long[1]]: [
        [-20.656186586282033, 76.48877047210927, 30.703586175062526],
        [104.70824655169996, 91.73185500110003, -111.74301985260001]
      ],
      [consts.size.long[2]]: [
        [-18.93424105628023, 57.613897872471576, 28.92726325739136],
        [104.7082465517, 91.7318550011, -111.74301985260001]
      ]
    }
  }
};
