import styled from "styled-components";
import { iconSizes } from "./consts";

const { space } = iconSizes;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const IconContainer = styled.div`
  padding: 0 ${space}px;
  position: relative;
  display: flex;
  justify-content: space-between;
`;
