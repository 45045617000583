import styled from "styled-components";
import { Box } from "grommet";

export const InfoIconBox = styled(Box)`
  position: absolute;
  ${({ top }) => top && `top: ${top}px;`}
  ${({ left }) => left && `left: ${left}px;`}
  ${({ right }) => right && `right: ${right}px;`}
  ${({ bottom }) => bottom && `bottom: ${bottom}px;`}
`;
