import { consts } from "state";
import { scaleNode, translateNode } from "./helpers";

export default async (sketchfab, { moduleType, wide, long }) => {
  scaleNode(sketchfab, "roof_def", [SCALE_X[moduleType][wide], 1, 1]);
  translateNode(sketchfab, "house_g", [
    TRANSLATE_X[moduleType][wide][long],
    0,
    0
  ]);
};

const SCALE_X = {
  [consts.moduleTypes[0]]: {
    [consts.size.wide[0]]: 0.4,
    [consts.size.wide[1]]: 0.6,
    [consts.size.wide[2]]: 0.8,
    [consts.size.wide[3]]: 1
  },
  [consts.moduleTypes[1]]: {
    [consts.size.wide[0]]: 0.476,
    [consts.size.wide[1]]: 0.71,
    [consts.size.wide[2]]: 0.963
  }
};

const TRANSLATE_X = {
  [consts.moduleTypes[0]]: {
    [consts.size.wide[0]]: {
      [consts.size.long[0]]: 101.5,
      [consts.size.long[1]]: 101.5,
      [consts.size.long[2]]: 101.5
    },
    [consts.size.wide[1]]: {
      [consts.size.long[0]]: 67.75,
      [consts.size.long[1]]: 67.75,
      [consts.size.long[2]]: 67.75
    },
    [consts.size.wide[2]]: {
      [consts.size.long[0]]: 34.0,
      [consts.size.long[1]]: 34.0,
      [consts.size.long[2]]: 34.0
    },
    [consts.size.wide[3]]: {
      [consts.size.long[0]]: 0,
      [consts.size.long[1]]: 0,
      [consts.size.long[2]]: 0
    }
  },
  [consts.moduleTypes[1]]: {
    [consts.size.wide[0]]: {
      [consts.size.long[0]]: 89.5,
      [consts.size.long[1]]: 89.5,
      [consts.size.long[2]]: 89.5
    },
    [consts.size.wide[1]]: {
      [consts.size.long[0]]: 49.0,
      [consts.size.long[1]]: 49.0,
      [consts.size.long[2]]: 49.0
    },
    [consts.size.wide[2]]: {
      [consts.size.long[0]]: 7.3,
      [consts.size.long[1]]: 7.3,
      [consts.size.long[2]]: 7.3
    },
    [consts.size.wide[3]]: {
      [consts.size.long[0]]: 0,
      [consts.size.long[1]]: 0,
      [consts.size.long[2]]: 0
    }
  }
};
