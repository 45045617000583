import React from "react";
import { Box, ResponsiveContext } from "grommet";
import { useGlobalState } from "state";
import { selectIsManual } from "state/selectors";
import MainSidebar from "./MainSidebar";
import ManualSidebar from "./ManualSidebar";
import DetailsPanel from "components/DetailsPanel";

export default () => {
  const [isManual] = useGlobalState(selectIsManual);
  const size = React.useContext(ResponsiveContext);
  const isSmall = size === "small";

  return (
    <Box overflow={{ vertical: "auto", horizontal: "hidden" }}>
      {isSmall ? !isManual && <MainSidebar /> : <MainSidebar />}
      {/* {isManual && <ManualSidebar isSmall={isSmall} />} */}
      <DetailsPanel />
    </Box>
  );
};
