import { consts } from "state";

export const ROTATE_Z = {
  [consts.orientation.flat]: 0,
  [consts.orientation.front]: 0,
  [consts.orientation.lateral]: -0.0872665
};

export const ROTATE_X = {
  [consts.orientation.flat]: 0,
  [consts.orientation.front]: -0.0872665,
  [consts.orientation.lateral]: 0
};

export const TRANSLATE_X = {
  [consts.orientation.flat]: 0,
  [consts.orientation.front]: 0,
  [consts.orientation.lateral]: -11
};

export const TRANSLATE_Z = {
  [consts.orientation.flat]: 0,
  [consts.orientation.front]: 6,
  [consts.orientation.lateral]: 0
};
