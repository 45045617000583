import React from "react";
import { Box, Text } from "grommet";
import theme from "components/theme";

export default ({
  isDisabled = false,
  isActive,
  onChange,
  children,
  ...rest
}) => (
  <Box
    onClick={onChange && !isDisabled ? onChange : () => {}}
    round="xsmall"
    width={`${theme.dimensionsInput.size}px`}
    height={`${theme.dimensionsInput.size}px`}
    border={getBorderStyle(isDisabled)}
    background={isActive ? "light-1" : "transparent"}
    align="center"
    alignContent="center"
    justify="center"
    style={{ cursor: !!onChange && !isDisabled ? "pointer" : "initial" }}
    {...rest}
  >
    <Text
      color={getColorText(isDisabled, isActive)}
      size="small"
      textAlign="center"
    >
      {children}
    </Text>
  </Box>
);

// const colors {
//   disale
// }

const getColorText = (isDisabled, isActive) => {
  if (isDisabled) {
    return "status-disabled";
  }

  return isActive ? "text" : "light-1";
};

const getBorderStyle = isDisabled => ({
  color: isDisabled ? "status-disabled" : "light-1",
  size: "1px"
});
