import { mat4 } from "gl-matrix";

export const getNodeByName = (sketchfab, nodeName) => {
  const resolver = (resolve, reject) => {
    sketchfab.getNodeMap(function(err, nodes) {
      err && reject(err);
      const nodeList = Object.values(nodes);

      const node = nodeList.find(({ name }) => name === nodeName);

      if (!node) {
        reject(new Error(`no node with name ${nodeName}`));
      }

      resolve(node);
    });
  };
  return new Promise(resolver);
};

const setMatrixPromise = (sketchfab, instanceID, destMatrix) =>
  new Promise((resolve, reject) => {
    sketchfab.setMatrix(instanceID, destMatrix, err => {
      if (err) {
        reject(err);
      }
      resolve();
    });
  });

export const showNodes = (sketchfab, nodeNames) => {
  nodeNames.forEach(nodeName => {
    sketchfab.node_show(nodeName);
  });
};

export const hideNodes = (sketchfab, nodeNames) => {
  nodeNames.forEach(nodeName => {
    sketchfab.node_hide(nodeName);
  });
};

export const translateNode = async (
  sketchfab,
  nodeName,
  translate = [1, 1, 1]
) => {
  const groundNode = await getNodeByName(sketchfab, nodeName);

  const destMatrix = mat4.create();

  mat4.translate(destMatrix, groundNode.localMatrix, translate);

  // sketchfab.setMatrix(groundNode.instanceID, destMatrix);
  return setMatrixPromise(sketchfab, groundNode.instanceID, destMatrix);
};

export const scaleNode = async (sketchfab, nodeName, scale = [1, 1, 1]) => {
  const groundNode = await getNodeByName(sketchfab, nodeName);

  const destMatrix = mat4.create();

  mat4.scale(destMatrix, groundNode.localMatrix, scale);

  return setMatrixPromise(sketchfab, groundNode.instanceID, destMatrix);
};

const translateZero = {
  x: 0,
  y: 0,
  z: 0
};

const scaleZero = {
  x: 1,
  y: 1,
  z: 1
};

const rotateZero = {
  x: 0,
  y: 0,
  z: 0
};

export const transformNode = async (
  sketchfab,
  nodeName,
  { translate = translateZero, scale = scaleZero, rotate = rotateZero }
) => {
  const node = await getNodeByName(sketchfab, nodeName);

  const destMatrix = mat4.create();

  const { x: translateX = 0, y: translateY = 0, z: translateZ = 0 } = translate;

  mat4.translate(destMatrix, node.localMatrix, [
    translateX,
    translateY,
    translateZ
  ]);

  const { x: scaleX = 1, y: scaleY = 1, z: scaleZ = 1 } = scale;

  mat4.scale(destMatrix, destMatrix, [scaleX, scaleY, scaleZ]);

  const { x: rotateX = 0, y: rotateY = 0, z: rotateZ = 0 } = rotate;

  mat4.rotateX(destMatrix, destMatrix, rotateX);

  mat4.rotateZ(destMatrix, destMatrix, rotateZ);

  mat4.rotateY(destMatrix, destMatrix, rotateY);

  sketchfab.setMatrix(node.instanceID, destMatrix);
};
