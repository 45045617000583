import React from "react";
import { settingOptions, utilizationOptions } from "./options";
import RadioButtonGroup from "components/RadioButtonGroup";
import { Text, Box } from "grommet";
import { useGlobalState, changeUtilization, changeSetting } from "state";
import { selectEnvironment, selectPreset } from "state/selectors";

export default () => {
  const [state, dispatch] = useGlobalState();

  const preset = selectPreset(state);
  const environment = selectEnvironment(state);
  const { setting, utilization } = environment;

  const onSettingChange = event => {
    dispatch(changeSetting(event.target.value));
  };

  const onUtilizationChange = event => {
    dispatch(changeUtilization(event.target.value));
  };

  return (
    <div>
      <Box margin={{ bottom: "medium" }}>
        <Text margin={{ bottom: "small" }}>Setting</Text>
        <RadioButtonGroup
          name="setting"
          options={settingOptions[preset]}
          value={setting}
          onChange={onSettingChange}
        />
      </Box>
      <Box margin={{ bottom: "medium" }}>
        <Text margin={{ bottom: "small" }}>Utilization</Text>
        <RadioButtonGroup
          name="utilization"
          options={utilizationOptions[preset]}
          value={utilization}
          onChange={onUtilizationChange}
        />
      </Box>
    </div>
  );
};
