import React from "react";
import { useGlobalState } from "state";
import {
  selectIsOpenManualStep,
  selectActiveMenuItem,
  selectIsAnnotationsVisible
} from "state/selectors";
import {
  annotations as defaultAnnotations,
  MANUAL_ANNOTATIONS_NAMES
} from "./consts";
import { Container, IconContainer } from "./AnnotationsPanel.style";
import Icon, { HomeIcon } from "./Icon";
import Description from "./Description";
import { useAnnotations } from "./AnnotationsPanel.hooks";

export default function({ sketchfab }) {
  const [state] = useGlobalState();

  const isAnnotationsVisible = selectIsAnnotationsVisible(state);
  const isManualStep = selectIsOpenManualStep(state);
  const activeMenuItem = selectActiveMenuItem(state);

  const annotations = isManualStep
    ? MANUAL_ANNOTATIONS_NAMES[activeMenuItem]
    : defaultAnnotations;

  const {
    hoveredAnnotation,
    activeAnnotation,
    highlightAnnotation,
    activateAnnotation,
    hideAnnotation
  } = useAnnotations(sketchfab);

  const onHover = name => highlightAnnotation(name);
  const onLeave = () => highlightAnnotation("");

  const onIconClick = name => {
    activateAnnotation(name);
  };

  const onHomeIconClick = () => {
    activateAnnotation("angle");
  };

  if (!sketchfab) {
    return null;
  }

  return (
    <Container>
      {hoveredAnnotation !== "home" ? hoveredAnnotation : null}
      <IconContainer>
        {isAnnotationsVisible &&
          annotations.map((name, index) => (
            <Icon
              key={name}
              name={name}
              isHovered={name === hoveredAnnotation}
              onHover={onHover}
              onLeave={onLeave}
              onIconClick={onIconClick}
              content={index + 1}
            />
          ))}
        <HomeIcon
          name={"home"}
          isHovered={"home" === hoveredAnnotation}
          onHover={onHover}
          onLeave={onLeave}
          onIconClick={onHomeIconClick}
        />
        {activeAnnotation ? (
          <Description name={activeAnnotation} onClose={hideAnnotation} />
        ) : null}
      </IconContainer>
    </Container>
  );
}
