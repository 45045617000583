import { ANNOTATIONS_NAMES } from "../Model/handlers/consts/annotations";

export {
  ANNOTATIONS_DATA,
  MANUAL_ANNOTATIONS_NAMES,
  MANUAL_ANNOTATIONS_POINTS
} from "../Model/handlers/consts/annotations";

export const annotations = ANNOTATIONS_NAMES.filter(
  name => name !== "Snow Brace"
);

// style

export const iconSizes = {
  size: 24,
  sizeGap: 8,
  space: 5, // margin between icons
  width: 24 + 8 + 2 * 5 // width of icon with its space
};
