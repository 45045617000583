export const cameraOptions = {
  orbit_constraint_pan: 1,
  orbit_constraint_zoom_out: 600,
  orbit_constraint_zoom_in: 60,
  orbit_constraint_pitch_down: 0
};

export const defaultOptions = {
  camera: 0,
  autostart: 1,
  autospin: 0,
  preload: 1,
  annotations_visible: 1,
  animation_autoplay: 1,
  ui_infos: 0,
  ui_annotations: 0,
  ui_animations: 0,
  ui_controls: 0,
  ui_stop: 0,
  ui_help: 0,
  ui_hint: 0,
  ui_general_controls: 0,
  ui_watermark: 0,
  transparent: 0,
  scrollwheel: 1,
  double_click: 0
};
