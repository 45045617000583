import React, { useState, useCallback, useEffect } from "react";
import ReactSVG from "react-svg";
import { Container, Button } from "./DimensionTrigger.style";
import DropSideModal from "components/DropSideModal";
import { useDimensionSizes } from "./DimensionTrigger.hooks";
import { useGlobalState, hideSidebarModal } from "state";
import { selectActiveSidebarModal } from "state/selectors";

export default function() {
  const [show, setShow] = useState(false);
  const [hasActiveModal, dispatch] = useGlobalState(selectActiveSidebarModal);

  const { rewriteSizes, svgImage } = useDimensionSizes();

  useEffect(() => {
    if (show && hasActiveModal) {
      setShow(false);
    }
  }, [hasActiveModal, show, dispatch]);

  const hideDimensionPanel = useCallback(() => setShow(false), [setShow]);

  const showDimensionPanel = useCallback(() => {
    if (!show && hasActiveModal) {
      dispatch(hideSidebarModal());
    }
    setShow(true);
  }, [show, hasActiveModal, dispatch]);

  return (
    <React.Fragment>
      <Button label="Show dimensions" onClick={showDimensionPanel} />
      {show && (
        <DropSideModal onClose={hideDimensionPanel}>
          <Container>
            <ReactSVG
              src={svgImage}
              beforeInjection={svg => {
                const sizes = svg.getElementsByTagName("text");
                rewriteSizes(sizes);
              }}
            />
          </Container>
        </DropSideModal>
      )}
    </React.Fragment>
  );
}
