import { consts } from "state";
import { transformNode, showNodes, hideNodes } from "./helpers";
import { SCALE_X as MODULE_TYPE_SCALE_X } from "./consts/moduleTypes";
import {
  TRANSLATE_X as ORIENTATION_TRANSLATE_X,
  ROTATE_Z as ORIENTATION_ROTATE_Z,
  ROTATE_X as ORIENTATION_ROTATE_X,
  TRANSLATE_Z as ORIENTATION_TRANSLATE_Z
} from "./consts/orientation";
import { TRANSLATE_Y as HEIGHT_TRANSLATE_Y } from "./consts/height";

export default async (
  sketchfab,
  { snowBrace, orientation, moduleType, wide, long, height }
) => {
  hideNodes(sketchfab, ALL_NODES);
  if (snowBrace) {
    const rotate = {
      z: ORIENTATION_ROTATE_Z[orientation],
      x: ORIENTATION_ROTATE_X[orientation]
    };

    const translate = {
      x: ORIENTATION_TRANSLATE_X[orientation],
      z: ORIENTATION_TRANSLATE_Z[orientation],
      y: HEIGHT_TRANSLATE_Y[height]
    };

    transformNode(sketchfab, "bracing", {
      rotate,
      translate,
      scale: { x: MODULE_TYPE_SCALE_X[moduleType] }
    });

    showNodes(sketchfab, [getBracingNode({ moduleType, long, wide })]);
  }

  handleAnnotation(sketchfab, snowBrace);
};

const ALL_NODES = [
  "bracing_5x7_4x7",
  "bracing_5x6_4x6",
  "bracing_5x5_4x5",
  "bracing_4x5_3x5",
  "bracing60_3x5",
  "bracing72_2x5",
  "bracing60_2x5",
  "bracing60_2x6",
  "bracing60_2x7",
  "bracing72_2x7",
  "bracing72_2x6",
  "bracing_4x6_3x6",
  "bracing_4x7_3x7",
  "bracing60_3x7",
  "bracing60_3x6"
];

const BRACING_NODES = {
  // moduletype 60
  [consts.moduleTypes[0]]: {
    // wide 5
    [consts.size.wide[3]]: {
      // long 7
      [consts.size.long[2]]: "bracing_5x7_4x7",
      // long 6
      [consts.size.long[1]]: "bracing_5x6_4x6",
      // long 5
      [consts.size.long[0]]: "bracing_5x5_4x5"
    },
    // wide 4
    [consts.size.wide[2]]: {
      // long 7
      [consts.size.long[2]]: "bracing_4x7_3x7",
      // long 6
      [consts.size.long[1]]: "bracing_4x6_3x6",
      // long 5
      [consts.size.long[0]]: "bracing_4x5_3x5"
    },
    // wide 3
    [consts.size.wide[1]]: {
      // long 7
      [consts.size.long[2]]: "bracing60_3x7",
      // long 6
      [consts.size.long[1]]: "bracing60_3x6",
      // long 5
      [consts.size.long[0]]: "bracing60_3x5"
    },
    // wide 2
    [consts.size.wide[0]]: {
      // long 5
      [consts.size.long[0]]: "bracing60_2x5",
      // long 6
      [consts.size.long[1]]: "bracing60_2x6",
      // long 7
      [consts.size.long[2]]: "bracing60_2x7"
    }
  },
  // moduletype 72
  [consts.moduleTypes[1]]: {
    // wide 2
    [consts.size.wide[0]]: {
      // long 5
      [consts.size.long[0]]: "bracing72_2x5",
      // long 6
      [consts.size.long[1]]: "bracing72_2x6",
      // long 7
      [consts.size.long[2]]: "bracing72_2x7"
    },
    // wide 3
    [consts.size.wide[1]]: {
      // long 5
      [consts.size.long[0]]: "bracing_4x5_3x5",
      // long 6
      [consts.size.long[1]]: "bracing_4x6_3x6",
      // long 7
      [consts.size.long[2]]: "bracing_4x7_3x7"
    },
    // wide 4
    [consts.size.wide[2]]: {
      // long 5
      [consts.size.long[0]]: "bracing_5x5_4x5",
      // long 6
      [consts.size.long[1]]: "bracing_5x6_4x6",
      // long 7
      [consts.size.long[2]]: "bracing_5x7_4x7"
    }
  }
};

export const getBracingNode = ({ wide, long, moduleType }) =>
  BRACING_NODES[moduleType][wide][long];

const handleAnnotation = (sketchfab, snowBrace) => {
  const index = sketchfab.annotations.findIndex(a => a.name === "Snow Brace");

  if (index !== -1) {
    snowBrace
      ? sketchfab.showAnnotation(index)
      : sketchfab.hideAnnotation(index);
  }
};
