import { consts } from "state";
import { scaleNode, transformNode } from "./helpers";

export default async (sketchfab, { moduleType, wide }) => {
  transformNode(sketchfab, NODE_NAMES.ground, {
    translate: { x: TRANSLATE_X[moduleType][wide] }
  });
};

const NODE_NAMES = {
  ground: "ground_02"
};

const TRANSLATE_X = {
  [consts.moduleTypes[0]]: {
    [consts.size.wide[0]]: -203,
    [consts.size.wide[1]]: -136,
    [consts.size.wide[2]]: -68,
    [consts.size.wide[3]]: 0
  },
  [consts.moduleTypes[1]]: {
    [consts.size.wide[0]]: -179,
    [consts.size.wide[1]]: -99,
    [consts.size.wide[2]]: -14.85,
    [consts.size.wide[3]]: -14.85
  }
};
