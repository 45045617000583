import styled from "styled-components";
import { Box } from "grommet";

const size = 24;

export const Wrapper1 = styled(Box)`
  width: ${size}px;
  height: ${size}px;
  text-align: center;
  line-height: ${size}px;
`;

Wrapper1.defaultProps = {
  round: "full",
  background: "light-1"
};

const sizeGap = 8;

export const Wrapper2 = styled(Box)`
  cursor: pointer;
  width: ${size + sizeGap}px;
  height: ${size + sizeGap}px;
  text-align: center;
  line-height: ${size + sizeGap}px;
  border: solid 1px #f8f8f8;
`;

Wrapper2.defaultProps = {
  round: "full",
  display: "flex",
  align: "center",
  justify: "center"
};
