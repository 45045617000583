import React, { useState } from "react";
import { useGlobalState } from "state";
import { selectIsManual } from "state/selectors";
import MainModel from "./MainModel";
import ManualModel from "./ManualModel";

export default () => {
  const [init, setInit] = useState(false);
  const [isManual] = useGlobalState(selectIsManual);

  return isManual ? (
    <ManualModel init={init} setInit={setInit} />
  ) : (
    <MainModel init={init} setInit={setInit} />
  );
};
