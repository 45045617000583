import React from "react";
import { Box, Text } from "grommet";
import {
  useGlobalState,
  changeWide,
  changeLong,
  changeModuleType,
  consts
} from "state";
import {
  selectSize,
  selectModuleType,
  selectDisabledSizes
} from "state/selectors";
import DimensionInput, { DimensionsBlock } from "components/DimensionInput";
import { Heading } from "./SizeInput.style";
import HeightInput from "components/HeightInput";

export default () => {
  const [state, dispatch] = useGlobalState();

  const size = selectSize(state);

  const moduleType = selectModuleType(state);

  const disabledSizes = selectDisabledSizes(state);

  const { long, wide } = size;

  const onWideChange = value => {
    dispatch(changeWide(value));
  };

  const onLongChange = value => {
    dispatch(changeLong(value));
  };

  const set60Modules = () => dispatch(changeModuleType(consts.moduleTypes[0]));
  const set72Modules = () => dispatch(changeModuleType(consts.moduleTypes[1]));

  return (
    <div>
      <Box margin={{ bottom: "large" }}>
        <Heading>Solar Module Layout</Heading>
        <Box
          direction="row"
          align="center"
          justify="center"
          gap="small"
          margin={{ bottom: "24px" }}
        >
          <DimensionsBlock>2/5</DimensionsBlock>
          <Text weight="bold"> = </Text>
          <Text weight="bold">Row/Column</Text>
        </Box>
        <Box direction="row">
          <Box basis="1/2" align="center">
            <DimensionInput
              disabledSizes={disabledSizes[consts.moduleTypes[0]]}
              label="60 Cell Modules"
              isActive={moduleType === consts.moduleTypes[0]}
              changeModuleType={set60Modules}
              onWideChange={onWideChange}
              onLongChange={onLongChange}
              columns={4}
              rows={3}
              long={parseInt(long)}
              wide={parseInt(wide)}
            />
          </Box>
          <Box basis="1/2" align="center">
            <DimensionInput
              disabledSizes={disabledSizes[consts.moduleTypes[1]]}
              label="72 Cell Modules"
              isActive={moduleType === consts.moduleTypes[1]}
              changeModuleType={set72Modules}
              onWideChange={onWideChange}
              onLongChange={onLongChange}
              columns={3}
              rows={3}
              long={parseInt(long)}
              wide={parseInt(wide)}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <Heading>Height</Heading>
        <HeightInput />
      </Box>
    </div>
  );
};
