import React from "react";
import { defaultOptions as options } from "./options";
import { useSketchfab, useDimensions } from "./Model.hooks";
import ModelView from "./ModelView";

const uid = "c9aadaedc1574e2caccb80e53d8aaafe";

export default function({ init, setInit }) {
  const frameId = "manual-model";

  const [isLoading, sketchfab] = useSketchfab(
    uid,
    frameId,
    options,
    init,
    setInit
  );

  useDimensions(sketchfab);

  return (
    <ModelView isLoading={isLoading} frameId={frameId} sketchfab={sketchfab} />
  );
}
