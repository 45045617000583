import styled from "styled-components";
import { Box, Tab, Tabs } from "grommet";
import theme from "components/theme";

export const StyledTab = styled(Tab)`
  margin-right: 12px;
  background-color: ${theme.tabs.tabBackground.disabled};
  &[aria-selected="true"] {
    background-color: ${theme.tabs.tabBackground.active};
  }
  & > div {
    border: none;
    padding-bottom: 0px;
  }
`;

StyledTab.defaultProps = {
  border: "none"
};

export const StyledTabs = styled(Tabs)`
  & > div:first-child {
    border-bottom: 1px solid ${theme.tabs.tabBackground.active};
  }
  & > div[role="tabpanel"] {
    overflow-y: scroll;
  }
`;

export const TabContentBox = styled(Box)``;
TabContentBox.defaultProps = {
  margin: { vertical: "medium" }
};
