import styled from "styled-components";

export default styled.div`
  .labelBlock {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .illustration {
      height: 30px;
      width: 45px;
      margin: 5px 10px 5px 6px;
    }
  }
`;
