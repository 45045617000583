import styled from "styled-components";
import { Box, Layer } from "grommet";
import theme from "components/theme";

export const SwipeBox = styled(Box)`
  height: 100%;
  position: relative;
  left: ${({ swipeTo }) => swipeTo}px;
  transition: left 0.2s ease-in-out;
`;

SwipeBox.defaultProps = {
  background: theme.sider.background
};

export const SwipeLayer = styled(Layer)`
  width: ${theme.sider.width};
  padding-top: ${theme.logo.height};
  height: 100%;
`;

SwipeLayer.defaultProps = {
  plain: true,
  responsive: false,
  modal: false,
  position: "left",
  animation: "slide"
};
