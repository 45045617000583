import { consts } from "state";
import { transformNode, hideNodes, showNodes } from "./helpers";
import {
  TRANSLATE_X,
  ROTATE_Z,
  ROTATE_X,
  TRANSLATE_Z
} from "./consts/orientation";
import { TRANSLATE_Y as HEIGHT_TRANSLATE_Y } from "./consts/height";

export default async (
  sketchfab,
  { orientation, moduleType, wide, long, height }
) => {
  const rotate = {
    z: ROTATE_Z[orientation],
    x: ROTATE_X[orientation]
  };

  const translate = {
    z: TRANSLATE_Z[orientation],
    x: TRANSLATE_X[orientation],
    y: HEIGHT_TRANSLATE_Y[height]
  };

  transformNode(sketchfab, "modules_72", { rotate, translate });
  transformNode(sketchfab, "modules_60", { rotate, translate });

  switch (moduleType) {
    case consts.moduleTypes[0]: {
      sketchfab.node_hide("modules_72");
      sketchfab.node_show("modules_60");

      break;
    }
    case consts.moduleTypes[1]: {
      sketchfab.node_hide("modules_60");
      sketchfab.node_show("modules_72");

      break;
    }

    default:
      break;
  }

  hideNodes(sketchfab, [
    ...MODULES_60_ROW_3,
    ...MODULES_60_ROW_4,
    ...MODULES_60_ROW_5,
    ...MODULES_60_COLUMN_7,
    ...MODULES_60_COLUMN_6,
    ...MODULES_72_ROW_3,
    ...MODULES_72_ROW_4,
    "module72_c6_r1",
    "module72_c6_r2",
    "module72_c7_r1",
    "module72_c7_r2"
  ]);
  const nodesToShow = getModuleNodeNames(wide, long, moduleType);
  showNodes(sketchfab, nodesToShow);
};

const create60Row = r => [1, 2, 3, 4, 5, 6, 7].map(n => `module60_c${n}_r${r}`);
const create72Row = r => [1, 2, 3, 4, 5, 6, 7].map(n => `module72_c${n}_r${r}`);

const create60Column = c => [1, 2, 3, 4, 5].map(n => `module60_c${c}_r${n}`);

export const getModuleNodeNames = (wide, long, moduleType) => {
  const nodesToShow = [];

  if (long === consts.size.long[1] && wide === consts.size.wide[0]) {
    nodesToShow.push(`module${moduleType}_c6_r1`);
    nodesToShow.push(`module${moduleType}_c6_r2`);
  }

  if (long === consts.size.long[2] && wide === consts.size.wide[0]) {
    nodesToShow.push(`module${moduleType}_c6_r1`);
    nodesToShow.push(`module${moduleType}_c6_r2`);
    nodesToShow.push(`module${moduleType}_c7_r1`);
    nodesToShow.push(`module${moduleType}_c7_r2`);
  }

  if (wide === consts.size.wide[1] && long === consts.size.long[0]) {
    [
      `module${moduleType}_c1_r3`,
      `module${moduleType}_c2_r3`,
      `module${moduleType}_c3_r3`,
      `module${moduleType}_c4_r3`,
      `module${moduleType}_c5_r3`
    ].forEach(n => nodesToShow.push(n));
  }

  if (wide === consts.size.wide[1] && long === consts.size.long[1]) {
    [
      `module${moduleType}_c6_r1`,
      `module${moduleType}_c6_r2`,
      `module${moduleType}_c1_r3`,
      `module${moduleType}_c2_r3`,
      `module${moduleType}_c3_r3`,
      `module${moduleType}_c4_r3`,
      `module${moduleType}_c5_r3`,
      `module${moduleType}_c6_r3`
    ].forEach(n => nodesToShow.push(n));
  }

  if (wide === consts.size.wide[1] && long === consts.size.long[2]) {
    [
      `module${moduleType}_c6_r1`,
      `module${moduleType}_c6_r2`,
      `module${moduleType}_c7_r1`,
      `module${moduleType}_c7_r2`,
      `module${moduleType}_c1_r3`,
      `module${moduleType}_c2_r3`,
      `module${moduleType}_c3_r3`,
      `module${moduleType}_c4_r3`,
      `module${moduleType}_c5_r3`,
      `module${moduleType}_c6_r3`,
      `module${moduleType}_c7_r3`
    ].forEach(n => nodesToShow.push(n));
  }

  if (wide === consts.size.wide[2] && long === consts.size.long[0]) {
    [
      `module${moduleType}_c1_r3`,
      `module${moduleType}_c2_r3`,
      `module${moduleType}_c3_r3`,
      `module${moduleType}_c4_r3`,
      `module${moduleType}_c5_r3`,
      `module${moduleType}_c1_r4`,
      `module${moduleType}_c2_r4`,
      `module${moduleType}_c3_r4`,
      `module${moduleType}_c4_r4`,
      `module${moduleType}_c5_r4`
    ].forEach(n => nodesToShow.push(n));
  }

  if (wide === consts.size.wide[2] && long === consts.size.long[1]) {
    [
      `module${moduleType}_c6_r1`,
      `module${moduleType}_c6_r2`,
      `module${moduleType}_c1_r3`,
      `module${moduleType}_c2_r3`,
      `module${moduleType}_c3_r3`,
      `module${moduleType}_c4_r3`,
      `module${moduleType}_c5_r3`,
      `module${moduleType}_c6_r3`,
      `module${moduleType}_c1_r4`,
      `module${moduleType}_c2_r4`,
      `module${moduleType}_c3_r4`,
      `module${moduleType}_c4_r4`,
      `module${moduleType}_c5_r4`,
      `module${moduleType}_c6_r4`
    ].forEach(n => nodesToShow.push(n));
  }

  if (wide === consts.size.wide[2] && long === consts.size.long[2]) {
    [
      `module${moduleType}_c6_r1`,
      `module${moduleType}_c6_r2`,
      `module${moduleType}_c7_r1`,
      `module${moduleType}_c7_r2`,
      `module${moduleType}_c1_r3`,
      `module${moduleType}_c2_r3`,
      `module${moduleType}_c3_r3`,
      `module${moduleType}_c4_r3`,
      `module${moduleType}_c5_r3`,
      `module${moduleType}_c6_r3`,
      `module${moduleType}_c7_r3`,
      `module${moduleType}_c1_r4`,
      `module${moduleType}_c2_r4`,
      `module${moduleType}_c3_r4`,
      `module${moduleType}_c4_r4`,
      `module${moduleType}_c5_r4`,
      `module${moduleType}_c6_r4`,
      `module${moduleType}_c7_r4`
    ].forEach(n => nodesToShow.push(n));
  }

  if (wide === consts.size.wide[3] && long === consts.size.long[0]) {
    [
      `module${moduleType}_c1_r3`,
      `module${moduleType}_c2_r3`,
      `module${moduleType}_c3_r3`,
      `module${moduleType}_c4_r3`,
      `module${moduleType}_c5_r3`,
      `module${moduleType}_c1_r4`,
      `module${moduleType}_c2_r4`,
      `module${moduleType}_c3_r4`,
      `module${moduleType}_c4_r4`,
      `module${moduleType}_c5_r4`
    ].forEach(n => nodesToShow.push(n));
    if (moduleType === consts.moduleTypes[0]) {
      [
        `module${moduleType}_c1_r5`,
        `module${moduleType}_c2_r5`,
        `module${moduleType}_c3_r5`,
        `module${moduleType}_c4_r5`,
        `module${moduleType}_c5_r5`
      ].forEach(n => nodesToShow.push(n));
    }
  }

  if (wide === consts.size.wide[3] && long === consts.size.long[1]) {
    [
      `module${moduleType}_c6_r1`,
      `module${moduleType}_c6_r2`,
      `module${moduleType}_c1_r3`,
      `module${moduleType}_c2_r3`,
      `module${moduleType}_c3_r3`,
      `module${moduleType}_c4_r3`,
      `module${moduleType}_c5_r3`,
      `module${moduleType}_c6_r3`,
      `module${moduleType}_c1_r4`,
      `module${moduleType}_c2_r4`,
      `module${moduleType}_c3_r4`,
      `module${moduleType}_c4_r4`,
      `module${moduleType}_c5_r4`,
      `module${moduleType}_c6_r4`
    ].forEach(n => nodesToShow.push(n));
    if (moduleType === consts.moduleTypes[0]) {
      [
        `module${moduleType}_c1_r5`,
        `module${moduleType}_c2_r5`,
        `module${moduleType}_c3_r5`,
        `module${moduleType}_c4_r5`,
        `module${moduleType}_c5_r5`,
        `module${moduleType}_c6_r5`
      ].forEach(n => nodesToShow.push(n));
    }
  }

  if (wide === consts.size.wide[3] && long === consts.size.long[2]) {
    [
      `module${moduleType}_c6_r1`,
      `module${moduleType}_c6_r2`,
      `module${moduleType}_c7_r1`,
      `module${moduleType}_c7_r2`,
      `module${moduleType}_c1_r3`,
      `module${moduleType}_c2_r3`,
      `module${moduleType}_c3_r3`,
      `module${moduleType}_c4_r3`,
      `module${moduleType}_c5_r3`,
      `module${moduleType}_c6_r3`,
      `module${moduleType}_c7_r3`,
      `module${moduleType}_c1_r4`,
      `module${moduleType}_c2_r4`,
      `module${moduleType}_c3_r4`,
      `module${moduleType}_c4_r4`,
      `module${moduleType}_c5_r4`,
      `module${moduleType}_c6_r4`,
      `module${moduleType}_c7_r4`
    ].forEach(n => nodesToShow.push(n));
    if (moduleType === consts.moduleTypes[0]) {
      [
        `module${moduleType}_c1_r5`,
        `module${moduleType}_c2_r5`,
        `module${moduleType}_c3_r5`,
        `module${moduleType}_c4_r5`,
        `module${moduleType}_c5_r5`,
        `module${moduleType}_c6_r5`,
        `module${moduleType}_c7_r5`
      ].forEach(n => nodesToShow.push(n));
    }
  }

  return nodesToShow;
};

const MODULES_60_ROW_3 = create60Row(3);
const MODULES_60_ROW_4 = create60Row(4);
const MODULES_60_ROW_5 = create60Row(5);

const MODULES_60_COLUMN_7 = create60Column(7);
const MODULES_60_COLUMN_6 = create60Column(6);

const MODULES_72_ROW_3 = create72Row(3);
const MODULES_72_ROW_4 = create72Row(4);
