import { translateNode } from "./helpers";
import {
  TRANSLATE_Z_02 as LONG_TRANSLATE_Z_02,
  TRANSLATE_Z_01 as LONG_TRANSLATE_Z_01
} from "./consts/long";

export default async (sketchfab, { long }) => {
  ["rail_bolts_01_01", "rail_bolts_02_01"].forEach(nodeName =>
    translateNode(sketchfab, nodeName, [0, 0, LONG_TRANSLATE_Z_02[long]])
  );

  ["rail_bolts_01_02", "rail_bolts_02_02"].forEach(nodeName =>
    translateNode(sketchfab, nodeName, [0, 0, LONG_TRANSLATE_Z_01[long]])
  );
};
