import React from "react";
import { Text, TableBody, TableRow, TableCell } from "grommet";
import { DimensionsBlock } from "components/DimensionInput";
import { StyledTable } from "./HeightInput.style";
import { heightOptions } from "./options";
import { consts, useGlobalState, changeHeight } from "state";
import { selectHeight } from "state/selectors";

export default () => {
  const [currentHeight, dispatch] = useGlobalState(selectHeight);
  const onHeightChange = v => dispatch(changeHeight(v));

  return (
    <StyledTable plain>
      <TableBody>
        <TableRow>
          {/* <TableCell scope="row">
            <Text>Height </Text>
          </TableCell> */}
          {heightOptions.map(({ value }) => (
            <TableCell key={value}>
              <DimensionsBlock
                isActive={currentHeight === value}
                onChange={() => onHeightChange(value)}
              >
                {value}’
              </DimensionsBlock>
            </TableCell>
          ))}
          <TableCell />
        </TableRow>
        {/* <TableRow>
          <TableCell scope="row">
            <Text>Clearance </Text>
          </TableCell>
          {consts.size.height.map(heightValue => (
            <TableCell key={heightValue} align="center">
              {heightValue === currentHeight && (
                <Text>{consts.clearance[heightValue]}’</Text>
              )}
            </TableCell>
          ))}
          <TableCell />
        </TableRow> */}
      </TableBody>
    </StyledTable>
  );
};
