import React from "react";
import consts from "state/consts";

export default [
  ...consts.colors.map(({ name, value, selectColor = value }) => ({
    label: (
      <div className="labelBlock">
        <div
          className="illustration"
          style={{ backgroundColor: selectColor }}
        ></div>
        <div className="name">{name}</div>
      </div>
    ),
    value
  }))
];
