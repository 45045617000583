import styled from "styled-components";
import { Layer, Box } from "grommet";
import theme from "components/theme";

export const DetailsLayer = styled(Layer)`
  width: ${({ full, isManual }) =>
    full ? "100%" : isManual ? theme.sider.manual.width : theme.sider.width};
  padding-top: ${({ full }) => (full ? "0px" : theme.logo.height)};
  height: 100%;
  z-index: ${({ full }) => (full ? 9999 : 15)};
`;

DetailsLayer.defaultProps = {
  plain: true,
  responsive: false,
  modal: false,
  position: "left",
  animation: "slide"
};

export const DetailsBox = styled(Box)`
  position: relative;
`;

DetailsBox.defaultProps = {
  background: "rgb(73, 77, 85)",
  height: "100%",
  pad: {
    top: "large",
    bottom: "small",
    horizontal: "medium"
  }
};

export const Close = styled.div`
  position: absolute;
  right: 14px;
  top: 14px;
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHgxPSIyIiB5MT0iMiIgeDI9IjE4IiB5Mj0iMTgiLz4NCjxsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHgxPSIxOCIgeTE9IjIiIHgyPSIyIiB5Mj0iMTgiLz4NCjwvc3ZnPg0K);
  content: "";
  cursor: pointer;
`;
