import { translateNode } from "./helpers";
import { TRANSLATE_Z_01, TRANSLATE_Z_02 } from "./consts/long";

export default async (sketchfab, { long }) => {
  ["pad_02_support_02", "pad_01_support_02"].forEach(nodeName =>
    translateNode(sketchfab, nodeName, [0, 0, TRANSLATE_Z_01[long]])
  );

  ["pad_02_support_01", "pad_01_support_01"].forEach(nodeName =>
    translateNode(sketchfab, nodeName, [0, 0, TRANSLATE_Z_02[long]])
  );
};
