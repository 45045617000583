import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  .sectorWrapper {
    padding: 20px;
    &:nth-last-of-type(4) {
      width: 25%;
    }
    &:nth-last-of-type(3) {
      width: 25%;
    }
    &:nth-last-of-type(2) {
      width: 35%;
    }
    &:nth-last-of-type(1) {
      width: 15%;
    }
    &:not(:last-of-type) {
      border-right: 1px solid rgba(255, 255, 255, 0.5);
    }
    .sector {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      .right {
        text-align: center;
        position: relative;
        margin-left: 20px;
      }
      .title {
        font-weight: bold;
      }
      .box {
        font-weight: bold;
        padding: 7px 22px;
        font-size: 18px;
        line-height: 24px;
        white-space: nowrap;
      }
      .priceBox {
        color: #fff;
        background-color: #48b11e;
      }
      .statsBox {
        color: #1f1d1b;
        background-color: #f9aa09;
      }
      .moreInfo {
        text-decoration: underline;
        text-transform: uppercase;
        font-size: 8px;
        position: absolute;
        bottom: -18px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        cursor: pointer;
      }
    }
    .button {
      width: 100%;
    }
  }
`;
