import React from "react";
import { Box, Image } from "grommet";
import logo from "./logo.png";
import theme from "components/theme";

export default ({ border = false }) => (
  <Box
    height={theme.logo.height}
    style={{ minHeight: theme.logo.height }}
    justify="start"
    pad={{ horizontal: "32px" }}
    background={theme.sider.background}
    border="bottom"
  >
    <Box width="120px">
      <Image fit="contain" src={logo} alt="logo" />
    </Box>
  </Box>
);
