import { consts } from "state";
import { transformNode, hideNodes, showNodes } from "./helpers";
import {
  SCALE_Z as LONG_SCALE_Z,
  TRANSLATE_Z_03 as LONG_TRANSLATE_Z
} from "./consts/long";

export default async (sketchfab, { long, moduleType, wide }) => {
  ALL_NODES.forEach(nodeName =>
    transformNode(sketchfab, nodeName, {
      translate: {
        z: LONG_TRANSLATE_Z[long]
      },
      scale: {
        z: LONG_SCALE_Z[long]
      }
    })
  );

  hideNodes(sketchfab, NODES_TO_HIDE);

  showNodes(sketchfab, NODES_TO_SHOW[moduleType][wide]);
};

const ALL_NODES = [
  "t_rail_01",
  "t_rail_02",
  "rail60_r1",
  "rail60_r2",
  "rail60_r3",
  "rail60_r4",
  "rail60_r5",
  "rail72_r1",
  "rail72_r2",
  "rail72_r3",
  "rail72_r4"
];

const NODES_TO_HIDE = [
  "rail60_r1",
  "rail60_r2",
  "rail60_r3",
  "rail60_r4",
  "rail60_r5",
  "rail72_r1",
  "rail72_r2",
  "rail72_r3",
  "rail72_r4"
];

const NODES_TO_SHOW = {
  [consts.moduleTypes[0]]: {
    [consts.size.wide[0]]: ["rail60_r1", "rail60_r2"],
    [consts.size.wide[1]]: ["rail60_r1", "rail60_r2", "rail60_r3"],
    [consts.size.wide[2]]: ["rail60_r1", "rail60_r2", "rail60_r3", "rail60_r4"],
    [consts.size.wide[3]]: [
      "rail60_r1",
      "rail60_r2",
      "rail60_r3",
      "rail60_r4",
      "rail60_r5"
    ]
  },
  [consts.moduleTypes[1]]: {
    [consts.size.wide[0]]: ["rail72_r1", "rail72_r2"],
    [consts.size.wide[1]]: ["rail72_r1", "rail72_r2", "rail72_r3"],
    [consts.size.wide[2]]: ["rail72_r1", "rail72_r2", "rail72_r3", "rail72_r4"],
    [consts.size.wide[3]]: ["rail60_r1", "rail60_r2", "rail60_r3", "rail60_r4"]
  }
};
