import consts from "state/consts";

export default [
  {
    label: "Flat",
    value: consts.orientation.flat
  },
  {
    label: "Front",
    value: consts.orientation.front
  },
  {
    label: "Lateral",
    value: consts.orientation.lateral
  }
];
