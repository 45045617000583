import { consts } from "state";
import { showNodes, hideNodes } from "./helpers";
import flatten from "lodash/flatten";

export default async (sketchfab, attachments) => {
  hideNodes(sketchfab, ALL_NODES);

  showNodes(sketchfab, NODE_NAMES[attachments]);
};

const NODE_NAMES = {
  [consts.attachments.pads]: ["pad_01", "pad_02"],
  [consts.attachments.beams]: ["beam_01_all", "beam_02_all"],
  [consts.attachments.anchors]: ["screw_01", "screw_02"],
  [consts.attachments.tTrack]: ["rail_01", "rail_02"]
};

const ALL_NODES = flatten(Object.values(NODE_NAMES));
