import React from "react";
import { ResponsiveContext } from "grommet";
import { DetailsLayer, DetailsBox, Close } from "./DropSideModal.style";
import theme from "components/theme";
import { useGlobalState } from "state";
import { selectIsManual } from "state/selectors";

export default function({ children, onClose }) {
  const [isManual] = useGlobalState(selectIsManual);
  const size = React.useContext(ResponsiveContext);
  const isSmall = size === "small";

  const isFull = isSmall ? true : false;
  const marginLeft = isSmall ? "0px" : theme.sider.width;

  return (
    <DetailsLayer
      margin={{ left: marginLeft }}
      full={isFull}
      isManual={isManual}
    >
      <DetailsBox>
        <Close onClick={onClose} />
        {children}
      </DetailsBox>
    </DetailsLayer>
  );
}
