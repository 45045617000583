import consts from "state/consts";

export const settingOptions = {
  [consts.presets.ground]: [
    {
      value: consts.environment.setting.empty,
      label: "No environment"
    },
    {
      value: consts.environment.setting.desert,
      label: "Desert"
    }
  ],
  [consts.presets.rooftop]: [
    {
      value: consts.environment.setting.empty,
      label: "No environment"
    },
    {
      value: consts.environment.setting.city,
      label: "City"
    }
  ]
};
export const utilizationOptions = {
  [consts.presets.ground]: [
    {
      value: consts.environment.utilization.none,
      label: "None"
    },
    {
      value: consts.environment.utilization.car,
      label: "Carport"
    },
    {
      value: consts.environment.utilization.hammock,
      label: "Hammock"
    },
    // {
    //   value: consts.environment.utilization.picnic,
    //   label: "Picnic Table"
    // },
    {
      value: consts.environment.utilization.dining,
      label: "Dining Table"
    }
  ],
  [consts.presets.rooftop]: [
    {
      value: consts.environment.utilization.none,
      label: "None"
    },
    // {
    //   value: consts.environment.utilization.picnic,
    //   label: "Picnic Table"
    // },
    {
      value: consts.environment.utilization.dining,
      label: "Dining Table"
    }
  ]
};
