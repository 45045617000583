import styled from "styled-components";
import { Box } from "grommet";

export const ModelPanel = styled(Box)`
  padding: 10px 25px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;

  & > * {
    pointer-events: all;
  }

  @media only screen and (max-width: 768px) {
    position: absolute;
    padding: 0 !important;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

export default styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: rgba(53, 54, 55, 0.7);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  .loading {
    color: #fff;
    &:after {
      content: ".";
      animation: dots 2s steps(5, end) infinite;
    }
  }
  iframe {
    border: none;
    transition: opacity 0.5s 0.2s ease-in-out;
    &.hidden {
      opacity: 0;
      height: 0;
      width: 0;
      top: 0;
      left: 0;
      pointer-events: none;
      position: absolute;
    }
    &.visible {
      max-height: 100%;
      height: 100%;
      width: 100%;
      opacity: 1;
      pointer-events: all;
      position: absolute;
    }
  }

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: white;
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
    }
  }
`;
